import * as api from '../api';
import {
  GET_POLITICIANS,
  ADD_REMOVE_POLITICIAN,
  SEARCH_POLITICIANS,
  HOVERED_POLITICIAN,
  GET_POLITICIAN,
  GET_NOTIFICATIONS,
} from '../constants/actionTypes';

export const fetchPoliticians = () => ({
  type: GET_POLITICIANS,
  payload: api.getPoliticians(),
});

export const searchPoliticians = (payload) => ({
  type: SEARCH_POLITICIANS,
  payload,
});

export const addRemovePolitician = (payload) => ({
  type: ADD_REMOVE_POLITICIAN,
  payload,
});

export const hoveredPolitician = (payload) => ({
  type: HOVERED_POLITICIAN,
  payload,
});

export const fetchPolitician = (politicianID) => ({
  type: GET_POLITICIAN,
  payload: api.getPolitician(politicianID),
});

export const fetchNotifications = (politicianID, year) => ({
  type: GET_NOTIFICATIONS,
  payload: api.getNotifications(politicianID, year),
  value: politicianID,
});
