import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import { API_URL } from '../api';

const Chip = styled.div`
  border-radius: 20px;
  margin: 10px 8px;
  position: relative;
  &:hover i {
    visibility: visible;
    opacity: 1;
  }
  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: 50% 50%;
    border: 1px solid white;
    box-shadow: ${(props) => `0 0 0 3px ${colors.parties[props.partyId]}`};
  }
  p {
    padding-left: 5px;
    font-size: 14px;
  }
  i {
    position: absolute;
    font-size: 15px;
    height: 16px;
    cursor: pointer;
    color: white;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    top: -7px;
    right: -5px;
    background-color: ${colors.parties[4]};
    border-radius: 50%;
    font-weight: 700;
    padding: 1px;
    padding-left: 2px;
  }
`;

function PoliticianChip(props) {
  const {
    politicianData: { photo, surname, fullName, partyId },
  } = props;
  const pic = photo ? `${API_URL}${photo}` : 'https://via.placeholder.com/150';
  const fullNameArray = fullName.split(' ');
  return (
    <Chip partyId={partyId}>
      <img src={pic} alt={fullName} />
      <i className="material-icons" onClick={props.handler}>
        close
      </i>
    </Chip>
  );
}

PoliticianChip.propTypes = {
  politicianData: PropTypes.object,
};

export default PoliticianChip;
