import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import Politician from './Politician';
import CollapsibleWithCharts from './CollapsibleWithCharts';
import YearSelect from './YearSelect';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPolitician } from '../actions/politicians';
import { setFilters } from '../actions';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1001;
  transform-origin: 50% 50%;
  height: 100%;
  &.container-enter {
    display: flex;
  }
  &.container-exit-done {
    display: none;
  }
`;

const CloseButton = styled.span`
  background-color: ${colors.app.lightGrey};
  border-radius: 50%;
  font-size: 30px;
  color: ${colors.app.secondary};
  cursor: pointer;
  font-weight: 900;
  padding: 2px;
  float: right;
`;

const PoliticianModalContainer = styled.div`
  background-color: ${colors.app.lightGrey};
  max-width: 980px;
  margin: 0 auto;
  border-radius: 25px;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 25%);
  padding: 0 0 0 20px;
  height: 80vh;
  max-height: 700px;
  z-index: 1001;
  width: 80%;
  @media (max-width: 890px) {
    width: 95%;
  }
  @media (max-width: 897px) and (orientation: landscape) {
    height: 93vh;
  }
  &.politicianModal-enter {
    display: flex;
    transform: scale(0.5);
    opacity: 0;
  }
  &.politicianModal-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: transform 200ms, opacity 200ms;
  }
  &.politicianModal-exit {
    transform: scale(1);
    opacity: 1;
  }
  &.politicianModal-exit-active {
    transform: scale(0.5);
    opacity: 0;
    transition: transform 200ms, opacity 200ms;
  }
  &.politicianModal-exit-done {
    display: none;
  }
  position: relative;
  display: flex;
`;

const ModalHeader = styled.div`
  width: 20%;
  position: relative;
  top: -60px;
  @media (max-width: 897px) and (orientation: landscape) {
    top: -25px;
  }
  padding-right: 20px;
`;

const ModalBody = styled.div`
  padding: 0px 0 30px 0;
  overflow-y: auto;
  background: ${colors.app.lightGrey};
  border-left: 12px solid white;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  width: 100%;
`;

const ModalFooter = styled.div`
  padding-top: 5px;
`;

const OpenBtn = styled.div`
  text-transform: uppercase;
  color: ${colors.app.secondary};
  cursor: pointer;
  padding: 15px 25px 5px 20px;
  font-weight: bold;
  transition: color 0.3s;
  text-align: right;
  &:hover {
    color: ${colors.app.lightSecondary};
  }
`;

const Backdrop = styled.div`
  backdrop-filter: blur(3px);
  background-color: #80808096;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100147;
  background-color: #008ba38a;
  z-index: 1000;
  &.backdrop-enter {
    display: block;
    opacity: 0;
  }
  &.backdrop-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.backdrop-exit {
    opacity: 1;
  }
  &.backdrop-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  &.backdrop-exit-done {
    display: none;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  border-bottom: 1px solid ${colors.app.grey};
  border-top: 1px solid ${colors.app.grey};
  width: 95%;
  padding: 5px;
  button {
    margin-left: 5px;
  }
  & > button:hover circle {
    fill: ${colors.app.darkSecondary}!important;
  }
`;

const YearDisplay = styled.div`
  font-weight: 900;
  font-size: 50px;
  color: #e5e5e5;
  background-color: ${colors.app.lightGrey};
  z-index: 1;
  padding: 5px;
`;

function PoliticianModal(props) {
  const dispatch = useDispatch();
  const [collapsibleOpen, setCollapsibleOpen] = useState(undefined);
  const [openAllBtn, setopenAllBtn] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [transitionFinished, setTransitionFinished] = useState(true);
  const {
    data: { politician, year, politicianYear },
    init,
  } = useSelector((state) => {
    return state.filters;
  });
  const selectedYear = politicianYear || year;

  useEffect(() => {
    if (init && politician) {
      dispatch(fetchPolitician(politician));
    }
  }, [dispatch, init, politician, year]);

  const dataset = useSelector((state) => {
    return state.politicians.clicked;
  });

  const updateYear = useCallback(
    (year) => {
      dispatch(
        setFilters({
          politicianYear: year,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setModalOpen(true);
  }, [dataset]);

  const openAllClick = useCallback(() => {
    if (collapsibleOpen !== undefined) {
      setCollapsibleOpen(!collapsibleOpen);
      setopenAllBtn(!openAllBtn);
    } else {
      if (openAllBtn) {
        setCollapsibleOpen(true);
        setopenAllBtn(!openAllBtn);
      } else {
        setCollapsibleOpen(false);
        setopenAllBtn(!openAllBtn);
      }
    }
    setInterval(() => {
      setCollapsibleOpen(undefined);
    }, 1000);
  }, [collapsibleOpen, openAllBtn]);

  const closeHandler = useCallback(() => {
    setopenAllBtn(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setCollapsibleOpen(false);
    setModalOpen(false);
    dispatch(setFilters({ politician: undefined, politicianYear: undefined }));

    setInterval(() => {
      setCollapsibleOpen(undefined);
    }, 1000);
  }, [dispatch]);

  const transitionListener = useCallback(() => {
    setTransitionFinished(!transitionFinished);
  }, [transitionFinished]);

  return (
    <div>
      {Object.entries(dataset).length !== 0 ? (
        <CSSTransition in={modalOpen} timeout={500} classNames="container">
          <Wrapper isOpen={modalOpen}>
            <CSSTransition
              in={modalOpen}
              timeout={200}
              classNames="politicianModal"
              onEntered={transitionListener}
              onExited={transitionListener}
            >
              <PoliticianModalContainer isOpen={modalOpen}>
                <div
                  style={{
                    width: '98%',
                    position: 'absolute',
                    top: '-12px',
                    right: '-12px',
                  }}
                >
                  <CloseButton
                    className="material-icons"
                    onClick={onCloseModal}
                  >
                    clear
                  </CloseButton>
                </div>
                <ModalHeader>
                  <Politician
                    politicianData={dataset.politician}
                    isSelectable={false}
                  />
                  <YearSelect
                    yearData={dataset.years}
                    clickHandler={updateYear}
                    selected={selectedYear}
                  />
                  <SocialContainer>
                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: colors.app.grey }}
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <TwitterIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: colors.app.grey }}
                      ></TwitterIcon>
                    </TwitterShareButton>
                  </SocialContainer>
                </ModalHeader>
                <ModalBody>
                  <YearDisplay>{selectedYear}</YearDisplay>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="incomes"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="savings"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="investments"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="loans"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                    transitionFinished={transitionFinished}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="companies"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="lands"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <CollapsibleWithCharts
                    data={dataset}
                    visualisedVar="vehicles"
                    year={selectedYear}
                    isOpen={collapsibleOpen}
                    onClose={closeHandler}
                  ></CollapsibleWithCharts>
                  <ModalFooter>
                    <OpenBtn onClick={openAllClick}>
                      {openAllBtn ? 'Ανοιγμα ολων' : 'Κλεισιμο ολων'}
                    </OpenBtn>
                  </ModalFooter>
                </ModalBody>
              </PoliticianModalContainer>
            </CSSTransition>
            <CSSTransition in={modalOpen} timeout={400} classNames="backdrop">
              <Backdrop />
            </CSSTransition>
          </Wrapper>
        </CSSTransition>
      ) : null}
    </div>
  );
}

PoliticianModal.propTypes = {
  dataset: PropTypes.object,
};

export default PoliticianModal;
