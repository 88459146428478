import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { messages } from '../constants/notificationsMessages';
import { removeNotification } from '../actions/notifications';
import SnackMessage from './NotificationSnackBar';
import useSelectedPoliticians from '../utils/hooks/useSelectedPoliticians';

let displayed = new Map();

const Notifier = (props) => {
  const year = useSelector((state) => {
    return state.year.selected;
  });
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const { selectedPoliticians } = useSelectedPoliticians();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const page = props.page.pathname.substring(1);

  useEffect(() => {
    notifications.forEach((noti, index) => {
      const politicianID = noti.id;
      const politicianName = selectedPoliticians.find(
        (politician) => politician.id === politicianID
      )?.fullName;
      const politicianPic = selectedPoliticians.find(
        (politician) => politician.id === politicianID
      )?.photo;
      const reasons = (noti.values && noti.values[page]?.reasons) || [];

      reasons.forEach((reason) => {
        const reasonKey = `${politicianID}-${page}-${reason}`;
        if (displayed.has(reasonKey)) return;
        const key = enqueueSnackbar(null, {
          autoHideDuration: 6000 * (index + 1),
          variant: 'info',
          action: (key) => (
            <SnackMessage
              id={key}
              message={`Ο ${politicianName} ανήκει ${messages[page][reason]} για το ${year}`}
              politicianPhoto={politicianPic}
              politicianName={politicianName}
              handleDismiss={closeSnackbar}
            />
          ),
          onExited: (event, key) => {
            displayed.delete(reasonKey);
            dispatch(removeNotification(politicianID, page, reason));
          },
        });
        displayed.set(reasonKey, key);
      });
    });
    const displayedKeys = [...displayed.keys()];
    displayedKeys.forEach((key) => {
      if (
        !key.includes(page) ||
        !notifications.some((notif) => notif.id === parseInt(key.split('-')[0]))
      ) {
        closeSnackbar(displayed.get(key));
      }
    });
  }, [notifications, page]); // eslint-disable-line

  return null;
};

export default Notifier;
