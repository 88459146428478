import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import { CollapsibleList } from '@rmwc/list';
import '@material/list/dist/mdc.list.css';
import '@rmwc/list/collapsible-list.css';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import {
  DataTable,
  DataTableBody,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
} from '@rmwc/data-table';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import BubbleChartContainer from './BubbleChartContainer';
import {
  formatNumber,
  sum,
  formatDecimal,
  formatThousandDecimal,
} from '../utils/Helpers';

const CollapsibleContainer = styled.div`
  border-bottom: 4px solid white;
  .rmwc-collapsible-list__handle {
    padding: 7px 20px;
  }

  .rmwc-collapsible-list__children {
    background-color: ${colors.app.white};
  }
`;

const BarRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 0.5fr 1.75fr 0.3fr;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: ${(props) => (props.topBar ? 'pointer' : '')};
  i {
    color: ${colors.app.secondary};
  }
`;

const Label = styled.span`
  text-align: left;
  margin-right: 30px;
  align-self: center;
  font-size: ${(props) => (props.topBar ? '14px' : '12px')};
  color: ${colors.app.black};
`;

const BarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bar = styled.div`
  height: ${(props) => (props.topBar ? '17px' : '10px')};
  background-color: ${colors.app.darkSecondary}; /* ${(props) =>
    colors.parties[props.party]}; */
  opacity: ${(props) => (props.topBar ? 1 : 0.4)};
  transition: all 1s;
  width: ${(props) => props.barWidth};
`;

const BarNum = styled.span`
  margin-left: 33px;
  color: ${colors.app.black};
  font-size: ${(props) => (props.topBar ? '16px' : '14px')};
  font-weight: ${(props) => (props.topBar ? 'bold' : 'regular')};
  padding-top: 2px;
`;

const DataTableStyled = styled(DataTable)`
  width: 100%;
  background-color: ${colors.app.white}!important;
  border: 0 !important;

  td,
  th {
    vertical-align: middle !important;
    height: 35px;
    font-size: 12px;
  }
  th {
    font-size: 12px;
    font-weight: bold;
  }
`;

function CollapsibleWithCharts(props) {
  const dataObject = props.data.declarations.filter((obj) => {
    return obj.year === props.year;
  })[0];
  const varsToBeBars = [
    'savings',
    'investments',
    'incomes',
    'loans',
    'companies',
  ];
  const varsHasBars = ['savings', 'incomes'];
  const varsHasTable = ['investments', 'lands', 'companies', 'vehicles'];
  const labelsMap = new Map([
    ['incomes', 'Εισοδήματα'],
    ['savings', 'Καταθέσεις'],
    ['investments', 'Επενδύσεις'],
    ['loans', 'Δάνεια'],
    ['lands', 'Γη/Ακίνητα'],
    ['companies', 'Εταιρείες'],
    ['vehicles', 'Οχήματα'],
  ]);
  const tablesLabelsMap = new Map([
    ['title', 'Τίτλος'],
    ['amount', 'Ποσότητα'],
    ['valuation', 'Αποτίμηση'],
    ['value', 'Αξία'],
    ['type', 'Τύπος'],
    ['land_area', 'Γη (τμ.)'],
    ['building_area', 'Ακίνητα (τμ.)'],
    ['name', 'Ονομα'],
    ['percentage', 'Ποσοστό'],
    ['fund_now', 'Κεφάλαιο εισφοράς έτους'],
    ['starting_fund', 'Αρχικό κεφάλαιο'],
    ['power', 'Κυβισμός'],
    ['municipality', 'Δήμος'],
  ]);
  const valueToParse =
    props.visualisedVar === 'investments'
      ? 'valuation'
      : props.visualisedVar === 'lands'
      ? 'building_area'
      : props.visualisedVar === 'loans'
      ? 'pending_amount'
      : props.visualisedVar === 'companies'
      ? 'fund_now'
      : 'amount';
  const isBar = varsToBeBars.includes(props.visualisedVar);
  const hasBars = varsHasBars.includes(props.visualisedVar);
  const hasTable = varsHasTable.includes(props.visualisedVar);
  const total = dataObject[props.visualisedVar]
    ? props.visualisedVar === 'vehicles'
      ? dataObject[props.visualisedVar].length
      : sum(dataObject[props.visualisedVar], valueToParse)
    : 0;

  let totalLand = 0;
  if (dataObject[props.visualisedVar]) {
    ({ totalLand } = dataObject[props.visualisedVar].reduce(
      (accumulator, { land_area, building_area }) => {
        if (land_area && building_area) {
          if (accumulator.sameLandAreasPerEntity[land_area]) {
            accumulator.sameLandAreasPerEntity[land_area] += 1;
          } else {
            accumulator.sameLandAreasPerEntity[land_area] = 1;
          }
        }

        const isSameLand =
          land_area &&
          building_area &&
          accumulator.sameLandAreasPerEntity[land_area] > 1;
        const hasNotBuilding = land_area && !building_area;

        if (hasNotBuilding || !isSameLand) {
          accumulator.totalLand += land_area;
        }

        return accumulator;
      },
      { totalLand: 0, sameLandAreasPerEntity: {} }
    ));
  }

  const party = props.data.politician.party;
  const noData = dataObject[props.visualisedVar].length <= 0 ? true : false;
  const tableDataMap = noData
    ? []
    : Object.keys(dataObject[props.visualisedVar][0]);

  const keysNotDisplay =
    props.visualisedVar === 'lands'
      ? [
          'id',
          'declaration',
          'country',
          'floor',
          'pool',
          'source',
          'spouse',
          'year',
          'created_by',
          'updated_by',
          'type_id',
        ]
      : [
          'id',
          'declaration',
          'country',
          'source',
          'spouse',
          'year',
          'titleoriginal',
          'created_by',
          'updated_by',
        ];
  let indexesFromKeys = [];
  for (let i = 0; i < keysNotDisplay.length; i++) {
    let index = tableDataMap.findIndex((rank) => rank === keysNotDisplay[i]);
    if (index !== -1) indexesFromKeys.push(index);
  }
  const barsScale = scaleLinear()
    .domain([0, max(Object.values(props.data.peak))])
    .range([0, 100]);

  return (
    <CollapsibleContainer>
      <CollapsibleList
        onClose={props.onClose}
        open={props.isOpen}
        handle={
          <BarRow topBar={true}>
            <Label topBar={true}>{labelsMap.get(props.visualisedVar)}</Label>
            <BarWrap>
              {isBar ? (
                <Bar
                  topBar={true}
                  barWidth={`${barsScale(total)}%`}
                  party={party}
                />
              ) : (
                <Bar topBar={true} barWidth={`0%`} />
              )}
              <BarNum topBar={true} party={party}>
                {props.visualisedVar === 'lands'
                  ? `${formatDecimal(totalLand)} τμ. / ${formatDecimal(
                      total
                    )} τμ.`
                  : props.visualisedVar === 'vehicles'
                  ? total
                  : formatNumber(total)}
              </BarNum>
            </BarWrap>
            {!noData ? (
              <i
                aria-hidden="true"
                className="rmwc-icon rmwc-icon--ligature material-icons mdc-list-item__meta"
              >
                chevron_right
              </i>
            ) : (
              ''
            )}
          </BarRow>
        }
      >
        {!noData ? (
          <div style={{ padding: '20px' }}>
            {hasBars ? (
              dataObject[props.visualisedVar].map((d, i) => {
                return (
                  <BarRow key={i}>
                    <Label>
                      {props.visualisedVar === 'incomes' ? d.type : d.bank}
                    </Label>
                    <BarWrap>
                      <Bar
                        topBar={false}
                        barWidth={`${(d.amount * 100) / total}%`}
                        party={party}
                      />
                      <BarNum topBar={false}>{formatNumber(d.amount)}</BarNum>
                    </BarWrap>
                  </BarRow>
                );
              })
            ) : hasTable ? (
              <DataTableStyled>
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      {tableDataMap
                        .filter(
                          (value, index) =>
                            indexesFromKeys.indexOf(index) === -1
                        )
                        .map((d, i) => {
                          return (
                            <DataTableHeadCell
                              key={i}
                              alignEnd={i === 0 ? false : true}
                            >
                              {tablesLabelsMap.get(d)}
                            </DataTableHeadCell>
                          );
                        })}
                    </DataTableRow>
                  </DataTableHead>
                  {!noData ? (
                    <DataTableBody>
                      {dataObject[props.visualisedVar].map((d, i) => {
                        return (
                          <DataTableRow key={i}>
                            {Object.values(d)
                              .filter(
                                (value, index) =>
                                  indexesFromKeys.indexOf(index) === -1
                              )
                              .map((data, indx) => {
                                return (
                                  <DataTableCell
                                    key={indx}
                                    alignEnd={indx === 0 ? false : true}
                                  >
                                    {typeof data === 'number'
                                      ? formatThousandDecimal(data)
                                      : data}
                                  </DataTableCell>
                                );
                              })}
                          </DataTableRow>
                        );
                      })}
                    </DataTableBody>
                  ) : (
                    ''
                  )}
                </DataTableContent>
              </DataTableStyled>
            ) : !noData ? (
              <BubbleChartContainer
                dataset={dataObject[props.visualisedVar]}
                party={props.data.politician.party}
                height={300}
                transitionFinished={props.transitionFinished}
              ></BubbleChartContainer>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </CollapsibleList>
    </CollapsibleContainer>
  );
}

CollapsibleWithCharts.propTypes = {
  data: PropTypes.object,
  visualisedVar: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CollapsibleWithCharts;
