import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as colors from '../Colors';
import { Chip, ChipSet } from '@rmwc/chip';
import '@material/chips/dist/mdc.chips.css';

const YearSelectContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors.app.grey};
  margin-top: 20px;
  @media (max-width: 897px) and (orientation: landscape) {
    margin-top: 10px;
  }
  position: relative;
  padding-bottom: 50px;
`;

const StyledChip = styled(Chip)`
  background-color: ${colors.app.secondary};
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 26px;
  &.mdc-chip--selected {
    transition: background-color 0.3s;
    background-color: ${colors.app.lightSecondary};
    color: ${colors.app.black};
  }
  &:hover {
    transition: background-color 0.3s;
    color: ${colors.app.black};
    background-color: ${colors.app.lightSecondary};
  }
`;

const StyledChipSet = styled(ChipSet)`
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 140px;
`;

const Label = styled.div`
  font-size: 14px;
`;

const DownloadWrapper = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors.app.grey};
`;

const DownloadBtn = styled.div`
  font-weight: 600;
  color: ${colors.app.secondary};
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  position: relative;
  cursor: pointer;
`;

const DownArrow = styled.div`
  color: ${colors.app.secondary};
  font-size: 33px !important;
`;

const PdfLabel = styled.div`
  position: absolute;
  top: 21px;
  color: white;
  left: 13.5px;
  font-size: 11px;
`;

function YearSelection(props) {
  const { yearData, selected } = props;
  const declarations = useSelector((state) => {
    return state.politicians.clicked.declarations;
  });

  const onClickPdf = useCallback(() => {
    let url = declarations.find(
      (declaration) => declaration.year === selected
    ).pdf;

    window.open(url, '_blank').focus();
  }, [declarations, selected]);

  return (
    <YearSelectContainer>
      <Label>Ετος</Label>
      <StyledChipSet filter>
        {yearData.map(function (year, i) {
          return (
            <StyledChip
              key={i}
              selected={year === selected ? true : false}
              onClick={() => props.clickHandler(year)}
              label={year}
            />
          );
        })}
      </StyledChipSet>
      {selected === 2022 && (
        <DownloadWrapper>
          Κατεβάστε τη δήλωση του {props.selected}
          <DownloadBtn onClick={onClickPdf}>
            <DownArrow className="material-icons">insert_drive_file</DownArrow>
            <PdfLabel>pdf</PdfLabel>
          </DownloadBtn>
        </DownloadWrapper>
      )}
    </YearSelectContainer>
  );
}

export default YearSelection;
