import React, { useEffect, useState, useRef } from 'react';
import FilterMenu from '../../components/FilterMenu';
import { yearData } from '../../constants';
import { fetchInvestments } from '../../actions/investments';
import { useSelector, useDispatch } from 'react-redux';
import BeeswarmContainer from '../../components/BeeswarmContainer';
import Tooltip from '../../components/Tooltip';
import styled from 'styled-components';
import { resetParty } from '../../actions/parties';
import { resetYear } from '../../actions/year';
import { resetFilters as resetFiltersAction, setFilters } from '../../actions';
import { debounce } from 'lodash';
import { pagesDescr } from '../../constants';
import { hoveredPolitician } from '../../actions/politicians';

const MainStyled = styled.main`
  min-height: 600px;
  padding: 16px;
  padding-bottom: 0px;
  height: calc(100% - 16px);
  display: flex;
  flex-flow: column;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const Note = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-size: 11px;
`;

const Investments = () => {
  const dispatch = useDispatch();
  const {
    data: { year, visualisedType },
    init,
  } = useSelector((state) => {
    return state.filters;
  });

  useEffect(() => {
    if (init) {
      dispatch(fetchInvestments(year));
    }
  }, [dispatch, init, year]);

  const politicians = useSelector((state) => {
    return state.investments.politicians;
  });

  const parties = useSelector((state) => {
    return state.investments.parties;
  });

  const filters = useSelector((state) => {
    return state.investments.filters;
  });

  const fetched = useSelector((state) => {
    return state.investments.loaded;
  });

  const [visualisedPoliticians, setVisualisedPoliticians] = useState([]);

  const timerRef = useRef(null);
  const hideTip = () => {
    timerRef.current = setTimeout(() => {
      dispatch(hoveredPolitician({}));
    }, 250);
  };

  useEffect(() => {
    setVisualisedPoliticians(politicians);
    return () => clearTimeout(timerRef.current);
  }, [politicians]);

  const updateVisualisedType = (type) => {
    dispatch(
      setFilters({
        visualisedType: type,
      })
    );
    setResetVisible(true);
  };

  const labelsData = [
    { label: 'Αποτίμηση', id: 'valuation' },
    { label: 'Αξία', id: 'value' },
  ];

  const [pressed, setPressed] = useState(false);
  const [selected, setSelected] = useState(labelsData[0].id);
  const togglePressed = (key) => {
    setPressed(!pressed);
    setSelected(key);
  };

  const sliderFilter = (min, max) => {
    const filtered = politicians.filter((politician) => {
      if (visualisedType === 'sum') {
        return (
          politician.extra[visualisedType] &&
          politician.extra[visualisedType][selected] >= min &&
          politician.extra[visualisedType][selected] <= max
        );
      } else {
        return (
          (politician.extra.records.some(
            (record) => record.key === visualisedType
          ) &&
            politician.extra.records.find(
              (record) => record.key === visualisedType
            ))[selected] >= min &&
          politician.extra.records.find(
            (record) => record.key === visualisedType
          )[selected] <= max
        );
      }
    });
    setVisualisedPoliticians(filtered);
  };

  const debouncedSliderFilter = debounce(sliderFilter, 300);

  const [resetVisible, setResetVisible] = useState(false);
  const resetState = (bool) => {
    setResetVisible(bool);
  };

  const resetFilters = () => {
    setResetVisible(false);
    dispatch(resetYear());
    dispatch(resetParty());
    dispatch(resetFiltersAction());
    //TODO: do we need below IncomesPage have it, check other pages also
    //  setVisualisedPoliticians(politicians);
  };

  return (
    <MainStyled>
      <FilterMenu
        visualisedVar="investments"
        partiesData={parties}
        itemsData={filters}
        yearsData={yearData}
        actionToDispatch={updateVisualisedType}
        switchLabels={labelsData}
        switchAction={togglePressed}
        switchPressed={pressed}
        resetState={resetState}
        reset={resetVisible}
        resetAction={resetFilters}
        visualisedType={visualisedType}
        secondVisualisedVar={selected}
        sliderAction={debouncedSliderFilter}
      />
      <Description>{pagesDescr.investments}</Description>
      <Note>(Ποσά σε €, άθροισμα βουλευτών και συζύγων)</Note>
      <BeeswarmContainer
        dataset={visualisedPoliticians}
        partiesData={parties}
        visualisedVar={visualisedType}
        secondVisualisedVar={selected}
        fetched={fetched}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      ></BeeswarmContainer>
      <Tooltip
        visualisedVar="investments"
        tooltipType="Bars"
        secondVisualisedVar={selected}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      />
    </MainStyled>
  );
};

export default Investments;
