import React, { useEffect, useState, useRef } from 'react';
import FilterMenu from '../../components/FilterMenu';
import { yearData } from '../../constants';
import { fetchLands } from '../../actions/lands';
import { useSelector, useDispatch } from 'react-redux';
import BeeswarmContainer from '../../components/BeeswarmContainer';
import Tooltip from '../../components/Tooltip';
import styled from 'styled-components';
import { resetParty } from '../../actions/parties';
import { resetYear } from '../../actions/year';
import { resetFilters as resetFiltersAction, setFilters } from '../../actions';
import { debounce } from 'lodash';
import { pagesDescr } from '../../constants';
import { hoveredPolitician } from '../../actions/politicians';

const MainStyled = styled.main`
  min-height: 600px;
  padding: 16px;
  padding-bottom: 0px;
  height: calc(100% - 16px);
  display: flex;
  flex-flow: column;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const Note = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-size: 11px;
`;

const LandsPage = () => {
  const dispatch = useDispatch();
  const {
    data: { year, visualisedType },
    init,
  } = useSelector((state) => {
    return state.filters;
  });

  useEffect(() => {
    if (init) {
      dispatch(fetchLands(year));
    }
  }, [dispatch, init, year]);

  const politicians = useSelector((state) => {
    return state.lands.politicians;
  });

  const parties = useSelector((state) => {
    return state.lands.parties;
  });

  const filters = useSelector((state) => {
    return state.lands.filters;
  });

  const fetched = useSelector((state) => {
    return state.lands.loaded;
  });

  const labelsData = [
    { label: 'Κτίρια', id: 'buildingArea' },
    { label: 'Γη', id: 'landArea' },
  ];

  const landAreaTypes = [
    'Βοσκότοπος',
    'Δασική έκταση',
    'Δενδροκαλλιέργια',
    'Καλλιέργεια',
    'Οικόπεδο',
  ];

  const [visualisedPoliticians, setVisualisedPoliticians] = useState([]);

  const timerRef = useRef(null);
  const hideTip = () => {
    timerRef.current = setTimeout(() => {
      dispatch(hoveredPolitician({}));
    }, 250);
  };

  useEffect(() => {
    setVisualisedPoliticians(politicians);
    return () => clearTimeout(timerRef.current);
  }, [politicians]);

  const [pressed, setPressed] = useState(false);
  const [selected, setSelected] = useState(labelsData[0].id);

  const sliderFilter = (min, max) => {
    const filtered = politicians.filter((politician) => {
      return (
        politician.extra[visualisedType] &&
        politician.extra[visualisedType][selected] >= min &&
        politician.extra[visualisedType][selected] <= max
      );
    });
    setVisualisedPoliticians(filtered);
  };

  const debouncedSliderFilter = debounce(sliderFilter, 300);

  const updateVisualisedType = (type) => {
    if (landAreaTypes.includes(type) && selected === labelsData[0].id) {
      togglePressed(labelsData[1].id);
    } else if (!landAreaTypes.includes(type) && selected === labelsData[1].id) {
      togglePressed(labelsData[0].id);
    }

    dispatch(
      setFilters({
        visualisedType: type,
      })
    );
    setResetVisible(true);
  };

  const togglePressed = (key) => {
    const filteredPoliticians = politicians.filter(
      (politician) =>
        politician.extra[visualisedType] &&
        politician.extra[visualisedType][key] &&
        politician.extra[visualisedType][key] !== null
    );
    setVisualisedPoliticians(filteredPoliticians);
    setPressed(!pressed);
    setSelected(key);
  };

  const [resetVisible, setResetVisible] = useState(false);
  const resetState = (bool) => {
    setResetVisible(bool);
  };

  const resetFilters = () => {
    setResetVisible(false);
    dispatch(resetYear());
    dispatch(resetParty());
    dispatch(resetFiltersAction());
    // setVisualisedPoliticians(politicians);
  };

  return (
    <MainStyled>
      <FilterMenu
        visualisedVar="lands"
        partiesData={parties}
        landTypes={filters}
        yearsData={yearData}
        actionToDispatch={updateVisualisedType}
        switchLabels={labelsData}
        switchAction={togglePressed}
        switchPressed={pressed}
        selected={selected}
        visualisedType={visualisedType}
        resetState={resetState}
        reset={resetVisible}
        resetAction={resetFilters}
        secondVisualisedVar={selected}
        sliderAction={debouncedSliderFilter}
      />
      <Description>{pagesDescr.lands}</Description>
      <Note>(Ποσά σε τ.μ., άθροισμα βουλευτών και συζύγων)</Note>
      <BeeswarmContainer
        dataset={visualisedPoliticians}
        partiesData={parties}
        visualisedVar={visualisedType}
        secondVisualisedVar={selected}
        fetched={fetched}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      ></BeeswarmContainer>
      <Tooltip
        visualisedVar="lands"
        tooltipType="Table"
        secondVisualisedVar={selected}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      />
    </MainStyled>
  );
};

export default LandsPage;
