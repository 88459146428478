import React, { useState, useMemo, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isEqual } from 'lodash';

import MainMenu from '../components/MainMenu';
import SearchComponent from '../components/SearchComponent';
import styled from 'styled-components';
import IncomesPage from './IncomesPage';
import SavingsPage from './SavingsPage';
import InvestmentsPage from './InvestmentsPage';
import LandsPage from './LandsPage';
import LoansPage from './LoansPage';
import CompaniesPage from './CompaniesPage';
import NotFoundPage from './NotFoundPage';
import PoliticianModal from '../components/PoliticianModal';

import Notifier from '../components/Notifier';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
//import { menuItems } from '../constants';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as colors from '../Colors';
import { initFilters, resetFiltersOnLocationChange } from '../actions';
import { getUrlFilters, cleanFiltersOnState } from '../utils/urlFilters';
import useDidUpdate from '../utils/hooks/useDidUpdate';
import useDidMount from '../utils/hooks/useDidMount';
import usePrevious from '../utils/hooks/usePrevious';
import qs from '../utils/queryString';
import LandingPage from '../components/LandingPage';
import MobileMessage from '../components/MobileMessage';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';
import SelectedPoliticians from '../components/SelectedPoliticians';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Grid = styled.div`
  display: grid;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  transition: all 0.5s;
`;

const MainBody = styled.div`
  padding: 0 4em 0 1em;
  flex: 1;
  height: calc(100% - 100px);
  position: relative;
  @media (max-width: 1024px) {
    padding: 0 4em 0 0em;
  }
`;

const LandingBody = styled.div`
  background: #323232;
`;

const Container = styled.div`
  height: 100%;
`;

const SideBar = styled.div`
  position: fixed;
  left: 100%;
  filter: drop-shadow(1px 0px 2px black);
  transition: all 0.5s;
  transform: ${(props) =>
    props.isSearchOpened ? 'translateX(-350px)' : 'translateX(0px)'};
`;

const StyledProgress = styled(LinearProgress)`
  height: 4px;
  background-color: ${colors.app.secondary}99;
  & .MuiLinearProgress-bar1Indeterminate,
  .MuiLinearProgress-bar2Indeterminate {
    background-color: ${colors.app.secondary};
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 4px;
  background-color: transparent;
`;

const ShareContainer = styled.div`
  position: absolute;
  left: 49px;
  top: 95px;
  display: flex;
  flex-flow: row;
  width: 70px;
  justify-content: space-between;
  & > button:hover circle{
    fill: ${colors.app.darkSecondary}!important;
  }
}
`;

const routes = [
  { path: '/', Component: LandingPage, exact: true },
  { path: '/incomes', Component: IncomesPage, exact: true },
  { path: '/savings', Component: SavingsPage, exact: true },
  { path: '/investments', Component: InvestmentsPage, exact: true },
  { path: '/lands', Component: LandsPage, exact: true },
  { path: '/loans', Component: LoansPage, exact: true },
  { path: '/companies', Component: CompaniesPage, exact: true },
  { path: '*', Component: NotFoundPage },
];

const AnimationApp = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: filters, urlSet } = useSelector((state) => {
    return state.filters;
  });
  const queryFilters = useMemo(() => cleanFiltersOnState(filters), [filters]);
  const previousQueryFilters = usePrevious(queryFilters);
  const previousPathName = usePrevious(location.pathname);

  useDidMount(() => {
    const urlFilters = getUrlFilters(location.search);
    dispatch(initFilters(urlFilters));
  });

  // update the url whenever the filters are changed
  // so we can link to the page
  useDidUpdate(() => {
    if (urlSet && !isEqual(queryFilters, previousQueryFilters)) {
      const { pathname } = location;
      history.push({ pathname, search: qs.stringify(queryFilters) });
      // history.replace({ pathname, search: qs.stringify(queryFilters) });
    }
  }, [queryFilters]);

  // reset url filters state when location is changing
  useDidUpdate(() => {
    const locationHasChanged = location.pathname !== previousPathName;

    if (locationHasChanged) {
      dispatch(resetFiltersOnLocationChange());
    }
  }, [location.pathname, previousPathName]);

  return (
    <Container>
      <TransitionGroup style={{ height: '100%' }}>
        <CSSTransition
          key={location.pathname}
          classNames="page"
          timeout={1000}
          exit={false}
          appear
        >
          <Switch location={location}>
            {routes.map(({ path, Component, exact }) => (
              <Route key={path} exact={exact} path={path}>
                <Component />
              </Route>
            ))}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Container>
  );
};
const useStyles = makeStyles(() => ({
  info: {
    backgroundColor: '#fff !important',
  },
}));

const App = () => {
  const classes = useStyles();
  const location = useLocation();
  const [searchOpened, setSearchOpened] = useState(false);
  const toggleSearchBar = () => {
    setSearchOpened(!searchOpened);
  };
  const { trackPageView } = useMatomo();
  const page = location.pathname.substring(1);
  const loading = useSelector((state) => {
    // wrong url redirected to 404 page in router
    if (!state[page]) {
      return false;
    }

    return state[page].loading;
  });
  useEffect(() => {
    // track page view on each location change
    trackPageView({
      documentTitle: page,
    });
  }, [location, page, trackPageView]);

  if (location.pathname === '/') {
    return (
      <Switch>
        <Route path="*">
          <LandingBody>
            <AnimationApp />
          </LandingBody>
        </Route>
      </Switch>
    );
  } else {
    return (
      <SnackbarProvider
        classes={{ variantInfo: classes.info }}
        hideIconVariant
        maxSnack={7}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid>
          <Notifier page={location} />
          <MobileMessage></MobileMessage>
          <PoliticianModal />
          <Wrapper isSearchOpened={searchOpened}>
            <header id="mainHeader">
              <MainMenu clickHandler={toggleSearchBar} />
              {loading ? <StyledProgress /> : <Placeholder />}
            </header>
            <Switch>
              <Route path="*">
                <MainBody>
                  <ShareContainer>
                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: colors.app.grey }}
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <TwitterIcon
                        size={32}
                        round={true}
                        bgStyle={{ fill: colors.app.grey }}
                      ></TwitterIcon>
                    </TwitterShareButton>
                  </ShareContainer>
                  <AnimationApp />
                </MainBody>
              </Route>
            </Switch>
            <SelectedPoliticians clickHandler={toggleSearchBar} />
          </Wrapper>
          {/* TODO: re-renders */}
          <SideBar isSearchOpened={searchOpened}>
            <SearchComponent clickHandler={toggleSearchBar}></SearchComponent>
          </SideBar>
        </Grid>
        <svg className={'circleSelection'}>
          <defs id="mydefs">
            <radialGradient id="linear">
              <stop id="a" offset="95%" stopColor={colors.app.lightGrey} />
              <stop id="b" offset="99%" stopColor={colors.app.black} />
            </radialGradient>
          </defs>
        </svg>
      </SnackbarProvider>
    );
  }
};

export default App;
