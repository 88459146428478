import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import PoliticianChip from './PoliticianChip';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
// import { addRemovePolitician } from '../actions/politicians';
import { removePoliticianAllNotifications } from '../actions/notifications';
import { setFilters } from '../actions';
import useSelectedPoliticians from '../utils/hooks/useSelectedPoliticians';
// import Tooltip from '@material-ui/core/Tooltip';
// import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@mui/icons-material/Add';

const SelectedPoliticiansContainer = styled.div`
  background-color: white;
  padding-top: 10px;
  border-left: 0.5px solid ${colors.app.grey};
  height: calc(100vh - 115px);
  // overflow-y: scroll;
  overflow: hidden;
  width: 70px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const SelectedPoliticiansScrollableArea = styled.div`
  max-height: calc(100vh - 185px);
  overflow-y: scroll;
`;

const PlusPoliticianIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px dashed ${colors.app.secondary};
  margin: 10px 0px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colors.app.secondary};
`;

const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  .politician {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-in;
    }
    &-exit {
      opacity: 0;
      transition: opacity 300ms ease-in;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 300ms ease-in;
    }
  }
`;

/* const Title = styled.h1`
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.app.grey};
`;

const NoPoliticians = styled.div`
  text-align: center;
  padding-top: 14%;
  font-weight: bold;
  color: ${colors.app.grey};
`; */

function SelectedPoliticians(props) {
  const dispatch = useDispatch();
  const { selectedPoliticians, selectedIds } = useSelectedPoliticians();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  return (
    <SelectedPoliticiansContainer>
      <SelectedPoliticiansScrollableArea>
        <TransitionGroup component={ChipsContainer}>
          {selectedPoliticians
            .sort(
              (a, b) => selectedIds.indexOf(a.id) - selectedIds.indexOf(b.id)
            )
            .map((politician) => (
              <CSSTransition
                key={`transition-${politician.id}`}
                timeout={500}
                classNames="politician"
              >
                <PoliticianChip
                  key={`politician-${politician.id}`}
                  politicianData={politician}
                  handler={() => {
                    dispatch(
                      setFilters({
                        selected: selectedIds.filter(
                          (id) => id !== politician.id
                        ),
                      })
                    );

                    // dispatch(addRemovePolitician({ id: politician.id }));

                    const notificObj = notifications.find(
                      (politiciansNotif) => {
                        return politiciansNotif.id === politician.id;
                      }
                    );
                    if (notificObj) {
                      dispatch(
                        removePoliticianAllNotifications(
                          politician.id,
                          notificObj
                        )
                      );
                    }
                  }}
                />
              </CSSTransition>
            ))}
        </TransitionGroup>
      </SelectedPoliticiansScrollableArea>
      <PlusPoliticianIcon onClick={props.clickHandler}>
        <AddIcon />
      </PlusPoliticianIcon>
    </SelectedPoliticiansContainer>
  );
}

SelectedPoliticians.propTypes = {
  selectedPoliticians: PropTypes.array,
};

export default SelectedPoliticians;
