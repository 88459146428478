import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';

const SwitchContainer = styled.div`
  width: ${(props) => `${props.width}px`};
  @media (max-width: 890px) {
    width: 120px;
    font-size: 12px;
  }
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: ${colors.app.lightGrey};
  display: flex;
  justify-content: space-around;
  height: 30px;
  align-items: center;
  border-radius: 5px;
  position: relative;
  align-self: center;
  span {
    cursor: pointer;
    width: 50%;
    text-align: center;
    z-index: 10;
  }
`;

const MovingBar = styled.div`
  position: absolute;
  width: 50%;
  height: 24px;
  background-color: white;
  border-radius: 5px;
  transition: left 0.8s;
  left: ${(props) =>
    props.isPressed === false
      ? '3px'
      : `calc(100% - ${props.width / 2 + 3}px)`};
  @media (max-width: 890px) {
    left: ${(props) =>
      props.isPressed === false ? '3px' : `calc(100% - ${120 / 2 + 3}px)`};
  }
`;

function Switch({ labels, width, actionToDispatch, pressed, selected }) {
  return (
    <SwitchContainer width={width}>
      <MovingBar isPressed={pressed} width={width} />
      {labels.map(function (label, i) {
        return (
          <span
            key={i}
            onClick={() => {
              if (selected !== label.id) actionToDispatch(label.id);
            }}
          >
            {label.label}
          </span>
        );
      })}
    </SwitchContainer>
  );
}

Switch.propTypes = {
  labels: PropTypes.array,
  width: PropTypes.number,
};

export default Switch;
