import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialIcon from 'material-icons-react';
import * as colors from '../Colors';

const Panel = styled.div`
  transition: transform 0.2s, opacity 0.2s;
  background-color: ${colors.app.darkSecondary};
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: ${(props) => (props.width ? `${props.width + 35}px` : '190px')};
  position: absolute;
  top: 50px;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  transform: ${(props) =>
    props.isOpen === true ? 'scale(1, 1)' : 'scale(0, 0)'};
  transform-origin: top left;
  margin-top: 5px;
  display: inline-block;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: 500px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  padding: 5px;
  color: white;
  display: inline-block;
  transition: opacity 0.1s linear 0.15s;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  visibility: ${(props) => (props.isOpen === true ? 'initial' : 'hidden')};
  pointer-events: none;
`;

const CloseButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 5px;
  transition: opacity 0.1s linear 0.15s;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  visibility: ${(props) => (props.isOpen === true ? 'initial' : 'hidden')};
`;

const PanelItem = styled.div`
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  padding: 10px 0 5px 5px;
  margin: 3px;
  cursor: pointer;
  transition: background 0.3s;
  transition: opacity 0.1s linear 0.15s, visibility 0.2s linear 0.2s;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  visibility: ${(props) => (props.isOpen === true ? 'initial' : 'hidden')};
  &:hover {
    background-color: ${colors.app.secondary};
  }
`;

function DropDownMenuDefault({
  isOpen,
  items,
  title,
  handleClck,
  handleSelection,
  width,
  actionToDispatch,
}) {
  return (
    <Panel width={width} isOpen={isOpen}>
      <Title isOpen={isOpen}>{title}</Title>
      <CloseButton isOpen={isOpen} onClick={handleClck}>
        <MaterialIcon icon="close" color="white" />
      </CloseButton>
      {items.map((item, i) => {
        return (
          <PanelItem
            isOpen={isOpen}
            key={i}
            onClick={() => {
              handleSelection(item);
              actionToDispatch(item);
            }}
          >
            {item}
          </PanelItem>
        );
      })}
    </Panel>
  );
}

DropDownMenuDefault.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.array,
  handleClck: PropTypes.func,
  handleSelection: PropTypes.func,
};

export default DropDownMenuDefault;
