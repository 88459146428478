export const app = {
  primary: '#052962',
  midPrimary: '#9CCAF6',
  lightPrimary: '#d1e4f6',
  darkSecondary: '#008ba3',
  secondary: '#00BCD4',
  lightSecondary: '#62efff',
  black: '#3d3d3d',
  darkGrey: '#808080',
  grey: '#c2c2c2',
  lightGrey: '#f5f5f5',
  lightGrey2: '#D4D7DB',
  orange: '#ef5350',
  lightPurple: '#ebe0fc',
  white: '#ffffff',
};

export const parties = {
  2: '#e91e63',
  1: '#1976d2',
  3: '#43a047',
  4: '#d32f2f',
  7: '#90caf9',
  10: '#f4511e',
  8: '#212121',
  9: '#2196f3',
  13: '#ef5350',
  14: '#fbc02d',
  12: '#ff5722',
  16: '#b0bec5',
  5: '#80deea',
  11: '#283593',
};
