import axios from 'axios';

export const API_URL = 'https://api.wealthobserver.gr';

export const getPoliticians = () => {
  const url = `${API_URL}/politicians?_limit=-1`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getPolitician = (politicianID, year) => {
  const url = `${API_URL}/politicians/${politicianID}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getΙncome = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=incomes&_limit=-1`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getSavings = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=savings&_limit=-1`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getLoans = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=loans&_limit=-1`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getInvestments = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=investments&_limit=-1`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getLands = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=lands&_limit=-1`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getCompanies = (year) => {
  const url = `${API_URL}/declarations/graph?year=${year}&type=companies&_limit=-1`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve(data))
      .catch((error) => {
        return reject(error);
      });
  });
};

export const getNotifications = (politicianID, year) => {
  const url = `${API_URL}/politicians/${politicianID}/select/${year}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => resolve({ politicianID: politicianID, data: data }))
      .catch((error) => {
        return reject(error);
      });
  });
};
