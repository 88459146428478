import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import * as colors from '../Colors';
import { IconButton } from '@rmwc/icon-button';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@rmwc/icon/icon.css';
import '@material/ripple/dist/mdc.ripple.css';
import { fetchNotifications } from '../actions/politicians';
import { setFilters } from '../actions';
import { API_URL } from '../api/';
import { removePoliticianAllNotifications } from '../actions/notifications';

const Container = styled.div`
  display: flex;
  width: ${(props) => (props.isProfileModal ? 'initial' : '300px')};
  padding: ${(props) =>
    props.isProfileModal ? '20px 0 0 0px' : '20px 0 0 20px'};
  &:nth-child(1) {
    padding-top: 0px;
  }
`;

const Avatar = styled.div`
  width: ${(props) => (props.isProfileModal ? '100px' : '55px')};
  height: ${(props) => (props.isProfileModal ? '100px' : '55px')};
  background-image: ${(props) => `url(${props.photo})`};
  @media (max-width: 897px) and (orientation: landscape) {
    width: ${(props) => (props.isProfileModal ? '80px' : '55px')};
    height: ${(props) => (props.isProfileModal ? '80px' : '55px')};
  }
  img {
    border-radius: 50%;
    width: ${(props) => (props.isProfileModal ? '100px' : '55px')};
    object-fit: cover;
    height: ${(props) => (props.isProfileModal ? '100px' : '55px')};
    object-position: 50% 50%;
    border: 1px solid white;
    box-shadow: ${(props) =>
      props.isProfileModal
        ? `0 0 0 4px ${colors.parties[props.partyId]}`
        : `0 0 0 3px ${colors.parties[props.partyId]}`};
    @media (max-width: 897px) and (orientation: landscape) {
      width: ${(props) => (props.isProfileModal ? '80px' : '55px')};
      height: ${(props) => (props.isProfileModal ? '80px' : '55px')};
    }
  }
`;

const InfoBox = styled.div`
  padding-top: ${(props) => (props.isProfileModal ? '10px' : '0')};
  padding-left: ${(props) => (props.isProfileModal ? '0px' : '10px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    padding-top: 5px;
    &:nth-child(1) {
      color: ${colors.app.grey};
      font-size: ${(props) => (props.isProfileModal ? '14px' : '12px')};
      font-weight: bold;
    }
    &:nth-child(2) {
      font-weight: ${(props) => (props.isProfileModal ? '600' : '500')};
      font-size: ${(props) => (props.isProfileModal ? '20px' : '16px')};
      line-height: ${(props) => (props.isProfileModal ? '1.2' : '1')};
      color: ${colors.app.black};
      @media (max-width: 897px) and (orientation: landscape) {
        font-size: 16px;
      }
    }
  }
  .partyContainer {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    p {
      padding-left: 5px;
      padding-top: 0;
      font-size: ${(props) =>
        props.isProfileModal ? '14px' : '12px'}!important;
      color: ${colors.app.darkGrey};
      letter-spacing: 0.5px;
    }
  }
`;

const PartyDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => colors.parties[props.partyId]};
  flex: none;
  margin-top: 2px;
`;

const SelectButton = styled.div`
  align-self: center;
  margin-left: auto;
  i {
    color: ${colors.app.secondary};
    &:nth-child(2) {
      color: ${colors.app.orange};
    }
  }
`;

const ClickContainer = styled.div`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  pointer-events: ${(props) => (props.clickable ? 'all' : 'none')};
  width: 100%;
  display: flex;
  flex-flow: ${(props) => (props.clickable ? 'initial' : 'column')};
  justify-content: ${(props) => (props.clickable ? 'initial' : 'center')};
  align-items: center;
`;

function Politician(props) {
  const {
    isSelectable,
    politicianData: politician,
    showNotification,
    politicianData: {
      photo,
      name,
      surname,
      fullName,
      district,
      party,
      id,
      partyId,
    },
  } = props;

  const year = useSelector((state) => {
    return state.filters.data.year;
  });

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const selectedParties = useSelector((state) => {
    return state.filters.data.parties;
  });

  const dispatch = useDispatch();
  //TODO:
  const selectedIds = useSelector((state) => {
    return state.filters.data.selected;
  });
  const isChecked = isSelectable
    ? selectedIds.some((politicianId) => politicianId === politician.id)
    : false;

  const onClick = useCallback(() => {
    dispatch(
      setFilters({
        selected: selectedIds.includes(politician.id)
          ? selectedIds.filter((id) => id !== politician.id)
          : [...selectedIds, politician.id],
      })
    );

    showNotification();

    if (!isChecked) {
      dispatch(fetchNotifications(politician.id, year));
      if (
        (!selectedParties[politician.partyId] ||
          selectedParties[politician.partyId === undefined]) &&
        politician.partyId !== 0
      ) {
        dispatch(
          setFilters({
            parties: {
              ...selectedParties,
              [politician.partyId]: !selectedParties[politician.partyId],
            },
          })
        );
      }
    } else if (isChecked) {
      const notificObj = notifications.find((politiciansNotif) => {
        return politiciansNotif.id === politician.id;
      });

      if (notificObj) {
        dispatch(removePoliticianAllNotifications(politician.id, notificObj));
      }
    }
  }, [
    dispatch,
    politician.id,
    politician.partyId,
    selectedIds,
    showNotification,
    isChecked,
    selectedParties,
    year,
    notifications,
  ]);

  return (
    <Container isProfileModal={!props.isSelectable}>
      <ClickContainer
        clickable={isSelectable}
        onClick={() => dispatch(setFilters({ politician: id }))}
      >
        <Avatar isProfileModal={!props.isSelectable} partyId={partyId}>
          <img
            src={
              photo
                ? `${API_URL}${photo}`
                : `${API_URL}/uploads/missing_photo.png`
            }
            alt={surname}
          />
        </Avatar>
        <InfoBox isProfileModal={!props.isSelectable} party={party}>
          <p>{district}</p>
          <p>{fullName ? fullName : `${name} ${surname}`}</p>
          <div className="partyContainer">
            <PartyDot partyId={partyId}></PartyDot>
            <p>{party}</p>
          </div>
        </InfoBox>
      </ClickContainer>
      {props.isSelectable ? (
        <SelectButton>
          <IconButton
            checked={isChecked}
            onClick={onClick}
            onIcon="remove_circle_outline"
            icon="add_circle_outline"
          />
        </SelectButton>
      ) : (
        ''
      )}
    </Container>
  );
}

Politician.propTypes = {
  politicianData: PropTypes.object,
  showNotification: PropTypes.func,
  isSelectable: PropTypes.bool,
  selectedPoliticians: PropTypes.array,
};

export default Politician;
