import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import * as colors from '../Colors';

const pages = [
  { label: 'Δες τα δεδομενα', url: '/incomes' },
  { label: 'About', url: '#about' },
  { label: 'API', url: 'https://github.com/wealthobserver/api' },
];

const MenuRight = styled(Box)({
  justifyContent: 'right',
});

const StyledAppBar = styled(AppBar)({
  background: 'transparent!important',
  boxShadow: 'none!important',
  borderBottom: '1px solid white',
});

const MenuLink = styled.a`
  text-decoration: none !important;
  color: ${(props) => (props.mobile ? '#323232' : 'white')};
  text-transform: uppercase;
  padding: 20px;
  font-weight: bold;
  transition: color 0.3s;
  font-family: 'Source Sans Pro';
  text-align: center;
  &:hover {
    color: ${colors.app.secondary};
  }
  p {
    padding: 0px 10px;
  }
`;

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Button
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuLink
                  href={page.url}
                  target={page.label === 'API' ? '_blank' : '_self'}
                  rel={page.label === 'API' ? 'noopener noreferrer' : ''}
                  mobile
                  key={page.label}
                  onClick={handleCloseNavMenu}
                >
                  <p style={{ textAlign: 'center' }}>{page.label}</p>
                </MenuLink>
              ))}
            </Menu>
          </Box>
          <Typography>
            <a href="/">
              <img
                width={70}
                height={42.95}
                style={{ width: '70px' }}
                alt="wealth observer logo"
                src="/logo_landing_beta.png"
              ></img>
            </a>
          </Typography>
          <MenuRight sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <MenuLink
                href={page.url}
                target={page.label === 'API' ? '_blank' : '_self'}
                rel={page.label === 'API' ? 'noopener noreferrer' : ''}
                key={page.label}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </MenuLink>
            ))}
          </MenuRight>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};
export default ResponsiveAppBar;
