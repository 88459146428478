export const initialState = {
  items: [
    { label: 'Εισoδημα', variable: 'incomes', notifications: 0 },
    { label: 'Καταθεσεις', variable: 'savings', notifications: 0 },
    { label: 'Επενδυσεις', variable: 'investments', notifications: 0 },
    { label: 'Ακινητα', variable: 'lands', notifications: 0 },
    { label: 'Οφειλες', variable: 'loans', notifications: 0 },
    { label: 'Εταιρειες', variable: 'companies', notifications: 0 },
  ],
  loading: false,
  hasErrors: false,
  selectedItem: { label: 'Εισoδημα', variable: 'incomes' },
};

export default function mainMenuReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SELECTED_ITEM':
      return { ...state, selectedItem: action.payload };
    case 'GET_NOTIFICATIONS_FULFILLED': {
      const itemsWithNoti = action.payload.data.notifications
        ? Object.keys(action.payload.data.notifications)
        : [];
      const newItems = state.items.map((item, index) => {
        if (!itemsWithNoti.includes(item.variable)) {
          return item;
        } else {
          return {
            ...item,
            notifications:
              item.notifications +
              action.payload.data.notifications[item.variable].reasons.length,
          };
        }
      });
      return {
        ...state,
        items: newItems,
      };
    }
    case 'REMOVE_NOTIFICATION': {
      const newItems = state.items.map((item, index) => {
        if (action.page !== item.variable) {
          return item;
        } else {
          return {
            ...item,
            notifications: item.notifications - 1,
          };
        }
      });
      return {
        ...state,
        items: newItems,
      };
    }
    case 'REMOVE_POLITICIAN_ALL_NOTIFICATIONS':
      const itemsWithNoti = action.notificationsObject.values
        ? Object.keys(action.notificationsObject.values)
        : [];

      const newItems = state.items.map((item, index) => {
        if (!itemsWithNoti.includes(item.variable)) {
          return item;
        } else {
          return {
            ...item,
            notifications:
              item.notifications -
              action.notificationsObject.values[item.variable].reasons.length,
          };
        }
      });
      return {
        ...state,
        items: newItems,
      };
    default:
      return state;
  }
}
