import { useRef, useEffect } from 'react';

const useDidUpdate = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, [func]);
};

export default useDidUpdate;
