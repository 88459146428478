import { isArray, isNil, omitBy, mapValues, pick, isUndefined } from 'lodash';

import qs from './queryString';

function cleanFilters(filters) {
  const newFilters = {
    ...filters,
  };

  if (filters.year) {
    newFilters.year = parseInt(newFilters.year);
  }

  if (filters.politicianYear) {
    newFilters.politicianYear = parseInt(newFilters.politicianYear);
  }

  if (filters.parties) {
    if (!isArray(filters.parties)) {
      newFilters.parties = [filters.parties];
    }

    newFilters.parties = newFilters.parties.reduce((accumulator, partyId) => {
      accumulator[partyId] = true;
      return accumulator;
    }, {});
  }

  if (filters.selected) {
    if (!isArray(filters.selected)) {
      newFilters.selected = [filters.selected];
    }

    newFilters.selected = newFilters.selected.map((value) =>
      parseInt(value, 10)
    );
  }

  if (filters.rangeMin && filters.rangeMax) {
    newFilters.range = {
      min: parseFloat(filters.rangeMin),
      max: parseFloat(filters.rangeMax),
    };

    delete newFilters['rangeMin'];
    delete newFilters['rangeMax'];
  }

  return newFilters;
}

export function getUrlFilters(queryString = '') {
  const urlFilters = qs.parse(queryString);
  const allowedFilters = [
    'year',
    'parties',
    'politician',
    'politicianYear',
    'selected',
    'visualisedType',
    'rangeMin',
    'rangeMax',
  ];

  return cleanFilters(pick(urlFilters, allowedFilters));
}

export const cleanFiltersOnState = (filters = {}) => {
  const notEmptyValues = omitBy(
    filters,
    (value) => isNil(value) || value.length === 0
  );

  const newValues = mapValues(notEmptyValues, (value, key) => {
    if (key === 'parties') {
      return Object.keys(value).filter((partyId) => value[partyId]);
    }

    return value;
  });

  if (newValues.range) {
    if (
      !isUndefined(newValues.range.min) &&
      !isUndefined(newValues.range.max)
    ) {
      newValues.rangeMin = newValues.range.min;
      newValues.rangeMax = newValues.range.max;
    }

    delete newValues['range'];
  }

  return newValues;
};
