import { ADD_REMOVE_PARTY, RESET_PARTY } from '../constants/actionTypes';

const initialState = {
  selectedParties: {
    1: true,
    2: true,
    3: true,
    4: true,
    7: true,
    10: true,
  },
};

export default function partiesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_REMOVE_PARTY:
      const selected = state.selectedParties;
      const selectedParty = action.payload;
      const newSelectedArray = {
        ...selected,
        [selectedParty]: !selected[selectedParty],
      };

      return { ...state, selectedParties: newSelectedArray };
    case RESET_PARTY:
      return { ...state, selectedParties: initialState.selectedParties };
    default:
      return state;
  }
}
