import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import SearchField from './SearchField';
import PoliticiansSearchList from './PoliticiansSearchList';
import { fetchPoliticians, searchPoliticians } from '../actions/politicians';
import { useSelector, useDispatch } from 'react-redux';

const SearchContainer = styled.div`
  background-color: ${colors.app.lightGrey};
  width: 350px;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.span`
  float: right;
  padding-top: 12px;
  padding-bottom: 7px;
  font-size: 30px;
  cursor: pointer;
  color: ${colors.app.secondary};
`;

const SearchComponent = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPoliticians());
  }, [dispatch]);

  const politicians = useSelector((state) => {
    return state.politicians.politicians.filter(({ declarations }) => {
      return declarations.includes(state.year.selected);
    });
  });

  const searchResults = useSelector((state) => {
    return state.politicians.searchResults;
  });

  const isSearching = useSelector((state) => {
    return state.politicians.searching;
  });

  const {
    data: { year },
  } = useSelector((state) => {
    return state.filters;
  });

  return (
    <SearchContainer>
      <div style={{ width: '98%' }}>
        <CloseButton className="material-icons" onClick={props.clickHandler}>
          clear
        </CloseButton>
      </div>
      <SearchField
        isMainSearch={true}
        label="ΟΝΟΜΑ/ΠΕΡΙΦΕΡΕΙΑ"
        typeHandler={(e) => {
          dispatch(
            searchPoliticians({
              term: e.target.value.toLowerCase(),
              year,
            })
          );
        }}
      />
      <PoliticiansSearchList
        politicians={isSearching ? searchResults : politicians}
      />
    </SearchContainer>
  );
};

SearchComponent.propTypes = {
  data: PropTypes.array,
};

export default SearchComponent;
