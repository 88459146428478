import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useSelectedPoliticians() {
  const politicians = useSelector((state) => {
    return state.politicians.politicians;
  });
  const selected = useSelector((state) => {
    return state.filters.data.selected;
  });

  const selectedPoliticians = useMemo(() => {
    return politicians.filter((politician) => selected.includes(politician.id));
  }, [politicians, selected]);

  return { selectedIds: selected, selectedPoliticians };
}
