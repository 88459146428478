import { ADD_REMOVE_PARTY, RESET_PARTY } from '../constants/actionTypes';

export const addRemoveParty = (payload) => ({
  type: ADD_REMOVE_PARTY,
  payload,
});

export const resetParty = () => ({
  type: RESET_PARTY,
});
