import { combineReducers } from 'redux';
import mainMenuReducer from './mainMenuReducer';
import politicians from './politicians';
import income from './income';
import parties from './parties';
import savings from './savings';
import loans from './loans';
import investments from './investments';
import lands from './lands';
import companies from './companies';
import year from './year';
import notifications from './notifications';
import filters from './filters';

const rootReducer = combineReducers({
  mainMenu: mainMenuReducer,
  politicians: politicians,
  income: income,
  parties: parties,
  savings: savings,
  loans: loans,
  investments: investments,
  lands: lands,
  companies: companies,
  year: year,
  notifications: notifications,
  filters: filters,
});

export default rootReducer;
