import {
  GET_POLITICIANS_PENDING,
  GET_POLITICIANS_FULFILLED,
  GET_POLITICIANS_REJECTED,
  SEARCH_POLITICIANS,
  ADD_REMOVE_POLITICIAN,
  HOVERED_POLITICIAN,
  GET_POLITICIAN_PENDING,
  GET_POLITICIAN_FULFILLED,
  GET_POLITICIAN_REJECTED,
} from '../constants/actionTypes';
import { normalizeGreek } from '../utils/Helpers';

export const initialState = {
  politicians: [],
  selected: [],
  searching: false,
  searchResults: [],
  loading: false,
  hasErrors: false,
  hovered: {},
  clicked: {},
};

export default function politiciansReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POLITICIANS_PENDING:
      return { ...state, loading: true };
    case GET_POLITICIANS_FULFILLED:
      return {
        ...state,
        politicians: action.payload,
        loading: false,
        hasErrors: false,
      };
    case GET_POLITICIANS_REJECTED:
      return { ...state, loading: false, hasErrors: true };
    case SEARCH_POLITICIANS: {
      const { politicians } = state;
      const {
        payload: { term, year },
      } = action;

      if (term === '') {
        return { ...state, searching: false, searchResults: [] };
      }

      const filteredData = politicians.filter(
        (politician) =>
          politician['declarations'].includes(year) &&
          (normalizeGreek(politician['district'])
            .toLowerCase()
            .includes(term) ||
            politician['district'].toLowerCase().includes(term) ||
            normalizeGreek(politician['fullName'])
              .toLowerCase()
              .includes(term) ||
            politician['fullName'].toLowerCase().includes(term))
      );

      return { ...state, searching: true, searchResults: filteredData };
    }
    case ADD_REMOVE_POLITICIAN: {
      const { selected } = state;
      const {
        payload: { id },
      } = action;
      const exists = selected.includes(id);

      let newSelected;
      if (!exists) {
        newSelected = [...selected, id];
      } else {
        newSelected = selected.filter((politicianId) => politicianId !== id);
      }

      return { ...state, selected: newSelected };
    }
    case HOVERED_POLITICIAN: {
      const hoveredObj =
        Object.keys(action.payload).length <= 4
          ? initialState.hovered
          : action.payload;
      return { ...state, hovered: hoveredObj };
    }
    case GET_POLITICIAN_PENDING:
      return { ...state, loading: true };
    case GET_POLITICIAN_FULFILLED:
      return {
        ...state,
        clicked: action.payload,
        loading: false,
        hasErrors: false,
      };
    case GET_POLITICIAN_REJECTED:
      return { ...state, loading: false, hasErrors: true };
    default:
      return state;
  }
}
