import qs from 'query-string';

// https://stackoverflow.com/a/37568368
const parse = (search) => qs.parse(search, { arrayFormat: 'comma' });

const stringify = (parsed) => qs.stringify(parsed, { arrayFormat: 'comma' });

export default {
  parse,
  stringify,
};
