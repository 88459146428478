import React, { useEffect } from 'react';
import BubbleChart from './BubbleChart';
import * as colors from '../Colors';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'lodash';

const Chart = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.app.white};
`;

function BubbleChartContainer(props) {
  const chart = new BubbleChart();

  useEffect(() => {
    chart.selection('#bubblechart').data(props.dataset).props({
      party: props.party,
      height: props.height,
    });
    if (props.transitionFinished) {
      chart.draw();
    }

    const handleResize = () => {
      chart.data(props.dataset).draw();
    };
    window.addEventListener('resize', debounce(handleResize, 250));
  });

  return <Chart id="bubblechart" />;
}

BubbleChartContainer.propTypes = {
  dataset: PropTypes.array,
  party: PropTypes.string,
  height: PropTypes.number,
};

export default BubbleChartContainer;
