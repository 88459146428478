import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_FULFILLED,
  GET_NOTIFICATIONS_REJECTED,
  REMOVE_NOTIFICATION,
  REMOVE_POLITICIAN_ALL_NOTIFICATIONS,
} from '../constants/actionTypes';

export const initialState = {
  notifications: [],
  loading: false,
  hasErrors: false,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_PENDING:
      return { ...state, loading: true };
    case GET_NOTIFICATIONS_FULFILLED: {
      let politicianNotification = {
        id: null,
        values: [],
      };
      politicianNotification.id = action.payload.politicianID;
      politicianNotification.values = action.payload.data.notifications;
      return {
        ...state,
        notifications: [...state.notifications, politicianNotification],
        loading: false,
        hasErrors: false,
      };
    }
    case GET_NOTIFICATIONS_REJECTED:
      return { ...state, loading: false, hasErrors: true };
    case REMOVE_NOTIFICATION:
      const notifObj = state.notifications.find(
        (obj) => obj.id === action.politicianID
      );
      const updatedNotifications = {
        id: action.politicianID,
        values: [],
      };
      if (notifObj) {
        updatedNotifications.values = Object.keys(notifObj.values)
          .filter((key) => key !== action.page)
          .reduce((obj, key) => {
            obj[key] = notifObj.values[key];
            return obj;
          }, {});
        updatedNotifications.values[action.page] = {};
        updatedNotifications.values[action.page].reasons = notifObj.values[
          action.page
        ].reasons.filter((reason) => reason !== action.reason);
      }

      const newState = state.notifications.map((notification) => {
        if (notification.id !== action.politicianID) {
          return notification;
        } else {
          return updatedNotifications;
        }
      });
      return {
        ...state,
        notifications: newState,
      };
    case REMOVE_POLITICIAN_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter((politicianObj) => {
          return politicianObj.id !== action.politicianID;
        }),
      };
    default:
      return state;
  }
}
