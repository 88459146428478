import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import Popover from '@material-ui/core/Popover';
import Politician from './Politician';

const ListDistanceFromTop = 115;
const ListDistanceFromBottom = 20;

function PoliticiansSearchList(props) {
  const { politicians } = props;
  const [open, setOpen] = useState(false);
  const [atLeastOneTimeShownNotification, setAtLeastOneTimeShownNotification] =
    useState(false);
  const refElement = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const showNotification = () => {
    if (!atLeastOneTimeShownNotification) {
      setAtLeastOneTimeShownNotification(true);
      setOpen(true);
    }
  };

  const Row = ({ index, style }) => {
    return (
      <div
        style={{
          borderBottom: '1px solid #ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style,
        }}
      >
        <Politician
          politicianData={politicians[index]}
          isSelectable={true}
          selectedPoliticians={props.selectedPoliticians}
          showNotification={showNotification}
        />
      </div>
    );
  };

  const vh = Math.max(
    document.documentElement.clientHeight || 0,

    window.innerHeight || 0
  );
  const listHeight = vh - ListDistanceFromTop - ListDistanceFromBottom;

  return (
    <div ref={refElement}>
      {refElement && refElement.current && (
        <Popover
          open={open}
          anchorEl={refElement.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <p
            style={{
              width: 290,
              padding: '10px 15px',
              fontFamily: 'Source Sans Pro',
              fontSize: '14px',
              lineHeight: '1.2',
            }}
          >
            Ο επιλεγμένος/η πολιτικός έχει επισημανθεί με έντονο περίγραμμα στο
            διπλανό γράφημα και μπορείτε να παρακολουθείτε τη δραστηριότητα του
            όσο πλοηγείστε.
          </p>
        </Popover>
      )}

      <List
        height={listHeight}
        itemCount={politicians.length}
        itemSize={100}
        width={'100%'}
        style={{ flex: 1 }}
      >
        {Row}
      </List>
    </div>
  );
}

PoliticiansSearchList.propTypes = {
  politicians: PropTypes.array,
  // addRemovePolitician: PropTypes.func,
  selectedPoliticians: PropTypes.array,
};

export default PoliticiansSearchList;
