import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialIcon from 'material-icons-react';
import DropDownMenuDefault from './DropDownMenuDefault';
import DropDownChipSelect from './DropDownChipSelect';
import * as colors from '../Colors';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const DroppedButton = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;
  padding: 5px 35px 5px 11px;
  border: 0px;
  border-radius: 5px;
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : '100px')};
  @media (max-width: 890px) {
    width: ${(props) => (props.width ? '98px' : '45px')};
    font-size: 14px;
  }
  height: 25px;
  margin: 5px 0;
  &:hover {
    background: ${colors.app.lightGrey}
      radial-gradient(circle, transparent 1%, ${colors.app.lightGrey} 1%)
      center/15000%;
  }
  &:active {
    background-color: ${colors.app.darkGrey};
    background-size: 100%;
    transition: background 0s;
  }
  &:focus {
    outline: 0;
  }
  label {
    position: absolute;
    transition: all 0.3s;
    transform-origin: left top;
    transform: ${(props) =>
      (props.type === 'normal' && props.isOpen === true) ||
      props.selected !== null
        ? 'translateY(-50%) scale(.70)'
        : 'translateY(0) scale(1)'};
    color: ${(props) =>
      (props.type === 'normal' && props.isOpen === true) ||
      props.selected !== null
        ? colors.app.secondary
        : colors.app.black};
  }
`;

const SelectedText = styled.span`
  width: 100%;
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-top: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SpanUp = styled.span`
  position: absolute;
  right: 0px;
  top: 7px;
`;
const SpanDown = styled.span`
  position: absolute;
  right: 0px;
  top: 7px;
`;

function Dropdown({
  label,
  items,
  resetState,
  variant,
  visualisedType,
  width,
  action,
}) {
  const year = useSelector((state) => {
    return state.filters.data.year;
  });

  visualisedType === 'sum' || visualisedType === undefined
    ? (visualisedType = null)
    : (visualisedType = visualisedType);
  const [opened, setOpened] = useState(false);

  let dropdownRef = React.createRef();
  const setSelection = (key) => {
    visualisedType = key;
  };

  // eslint-disable-next-line
  const icon = opened ? 'arrow_drop_up' : 'arrow_drop_down';

  function handleClick(e) {
    if (dropdownRef.current.contains(e.target)) {
      setOpened(!opened);
    } else {
      if (
        variant === 'normal' ||
        e.target === e.currentTarget ||
        e.target.parentNode.classList.contains('-jsCloseBtn')
      ) {
        setOpened(false);
      }
    }
  }

  const handleClickAway = () => {
    setOpened(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div onClick={handleClick}>
        <DroppedButton
          isOpen={opened}
          type={variant}
          selected={label === 'Ετος' ? year : visualisedType}
          ref={dropdownRef}
          width={width}
        >
          <label>{label}</label>
          <SelectedText>
            {label === 'Ετος' ? year : visualisedType}
          </SelectedText>
          <SpanUp style={opened ? { opacity: 1 } : { opacity: 0 }}>
            <MaterialIcon icon="arrow_drop_up" color={colors.app.secondary} />
          </SpanUp>
          <SpanDown style={opened ? { opacity: 0 } : { opacity: 1 }}>
            <MaterialIcon icon="arrow_drop_down" color={colors.app.secondary} />
          </SpanDown>
        </DroppedButton>
        {(function () {
          switch (variant) {
            case 'normal':
              return (
                <DropDownMenuDefault
                  width={width}
                  isOpen={opened}
                  title={label}
                  items={items}
                  handleClck={handleClick}
                  handleSelection={setSelection}
                  actionToDispatch={action}
                />
              );
            case 'chipset':
              return (
                <DropDownChipSelect
                  width={width}
                  isOpen={opened}
                  title={label}
                  items={items}
                  handleClck={handleClick}
                  resetState={resetState}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </ClickAwayListener>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  variant: PropTypes.string,
};

export default Dropdown;
