export const messages = {
  incomes: {
    amount: 'στους 30 βουλευτές με τα μεγαλύτερα εισοδήματα',
  },
  savings: {
    amount: 'στους 30 βουλευτές με τις μεγαλύτερες καταθέσεις',
  },
  loans: {
    amount: 'στους 30 πιο υπερχρεωμένους βουλευτές',
  },
  investments: {
    valuation: 'στους 30 βουλευτές με τη μεγαλύτερη αξία επενδυτικών προϊόντων',
    value: 'στους 30 βουλευτές με τη μεγαλύτερη αξία επενδυτικών προϊόντων',
  },
  lands: {
    buildingArea:
      'στους 30 βουλευτές με τα περισσότερα τ.μ. ακινήτων στην κατοχή τους',
    landArea: 'στους 30 βουλευτές με τα περισσότερα τ.μ. γης στην κατοχή τους',
  },
  companies: {
    startingFund:
      'στους 30 βουλευτές με τα περισσότερα κεφάλαια σε επιχειρήσεις',
    fundNow: 'στους 30 βουλευτές με τα περισσότερα κεφάλαια σε επιχειρήσεις',
  },
};
