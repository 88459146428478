import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #323232;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Inner = styled.div`
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  p {
    font-size: 22px;
    color: white;
    line-height: 1.3;
    padding: 40px 0;
  }
  img {
    width: 75%;
    margin: 0 auto;
  }
`;

function MobileMessage() {
  return (
    <Wrapper className="mobile-message">
      <Inner>
        <p>
          Για τη βέλτιστη εμπειρία παρακαλούμε δείτε την εφαρμογή σε μεγαλύτερη
          οθόνη.
        </p>
        <img src="/rotate.png" alt="Rotate"></img>
        <p>
          Για να δείτε μια μη βελτιστοποιημένη έκδοση στο κινητό σας, γυρίστε το
          σε οριζόντια θέση.
        </p>
      </Inner>
    </Wrapper>
  );
}

export default MobileMessage;
