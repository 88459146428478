import React from 'react';
import PropTypes from 'prop-types';
import { Chip, ChipSet } from '@rmwc/chip';
import '@material/chips/dist/mdc.chips.css';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../actions';

const StyledChip = styled(Chip)`
  background-color: transparent;
  border: 1px white solid;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: visibility 0.2s linear 0.2s;
  visibility: ${(props) => (props.isopen === 'true' ? 'initial' : 'hidden')};
  &:hover {
    background-color: ${(props) => props.partycolor};
    color: white;
  }
  path {
    stroke: white;
  }
`;
// eslint-disable-next-line
const StyleChipSet = styled(ChipSet)`
  transition: visibility 0.2s;
  visibility: ${(props) => (props.isopen === 'true' ? 'initial' : 'hidden')};
`;

function ChipsetFilter({ isOpen, items, resetState }) {
  const dispatch = useDispatch();

  const selectedParties = useSelector((state) => {
    return state.filters.data.parties;
  });

  return (
    <StyleChipSet isopen={isOpen.toString()} filter>
      {items.map(function (item, i) {
        return (
          <StyledChip
            isopen={isOpen.toString()}
            key={i}
            partycolor={item.color}
            selected={selectedParties[item.id]}
            checkmark
            onClick={() => {
              resetState(true);
              //dispatch(addRemoveParty(item.id));
              dispatch(
                setFilters({
                  parties: {
                    ...selectedParties,
                    [item.id]: !selectedParties[item.id],
                  },
                })
              );
            }}
            label={item.Abbreviation ? item.Abbreviation : item.name}
          />
        );
      })}
    </StyleChipSet>
  );
}

ChipsetFilter.propTypes = {
  isOpen: PropTypes.bool,
  items: PropTypes.array,
};

export default ChipsetFilter;
