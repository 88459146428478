import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import * as colors from '../Colors';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../api/';
import { formatNumber, normalizeGreek } from '../utils/Helpers';

const Wrapper = styled.div`
  display: ${({ noData }) => (noData ? 'none' : 'block')};
`;

const Tip = styled.div`
  max-width: 220px;
  min-width: 100px;
  position: absolute;
  left: ${(props) => props.tipX}px;
  top: ${(props) => props.tipY}px;
  display: ${({ noData }) => (noData ? 'none' : 'flex')};
  justify-content: center;
`;

const TipContainer = styled.div`
  background-color: white;
  padding: 10px 10px 10px 10px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  & p {
    font-size: 11px;
    color: #808080;
    margin-top: 5px;
  }
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const DataTableHeadCell = styled.th`
  font-size: 12px !important;
  padding: 5px 0 5px 0;
`;

/* const DataTableHeadNumCell = styled.th`
  font-size: 14px !important;
  padding: 5px 10px 5px 0;
  font-weight: bold;
  text-align: right;
`; */

const DataTableRow = styled.tr`
  border-top: 1px solid ${colors.app.darkGrey};
  &:nth-child(1) {
    border-top: none;
  }
`;

const DataTableCell = styled.td`
  font-size: 12px !important;
  padding: 5px 0 5px 0;
`;

const DataTableNumCell = styled.td`
  font-size: 14px !important;
  padding: 5px 10px 5px 0;
  font-weight: bold;
  text-align: right;
`;

const DataBarContainer = styled.div`
  &:nth-child(1) {
    border-bottom: 1px solid ${colors.app.darkGrey};
  }
`;

const DataBarsRow = styled.div`
  display: flex;
  align-items: center;
`;

const DataBarsCell = styled.div`
  font-size: 12px !important;
  padding: 9px 0 1px 0;
  margin-right: 20px;
`;

const DataBarsNumCell = styled.div`
  font-size: 14px !important;
  padding: 9px 10px 1px 0;
  font-weight: normal;
  text-align: right;
  flex: 1;
`;

const DataBar = styled.div`
  height: 3px;
  background-color: ${colors.app.lightGrey};
`;

const DataInnerBar = styled.div`
  height: 3px;
  background-color: ${(props) =>
    props.mainBar === true
      ? colors.app.darkSecondary
      : colors.parties[props.party]};
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity:0;
  }
`;

const TipHead = styled.div`
  position: absolute;
  top: ${(props) => `${props.yPos - 84}px`};
  left: ${(props) => `${props.xPos}px`};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transform: translate(-50%, 0);
`;

const PoliticianImg = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: ${colors.app.lightGrey}CC;
  background-image: ${(props) => `url(${props.image})`};
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  border: ${(props) => `3px solid ${props.color}`};
  pointer-events: none;
  &.politician-exit {
    animation: 0.9s ${fadeOut};
  }
  .politician-exit-active {
    animation: 0.9s ${fadeOut};
  }
`;

const Name = styled.div`
  font-size: 16px !important;
  font-weight: 900;
  padding-bottom: 5px;
  width: auto;
  text-align: center;
  background-color: ${colors.app.lightGrey};
  padding: 4px;
  color: ${(props) => `${props.color}`};
  white-space: nowrap;
  text-transform: uppercase;
`;

const objectsMapPlural = new Map([
  ['investments', 'επενδυτικά προϊόντα'],
  ['lands', 'ακίνητα'],
  ['incomes', 'τύποι εισοδήματος'],
  ['savings', 'καταθέσεις'],
  ['loans', 'οφειλές'],
  ['companies', 'εταιρείες'],
]);
const objectsMapSingular = new Map([
  ['investments', 'επενδυτικό προϊόν'],
  ['lands', 'ακίνητο'],
  ['incomes', 'τύπος εισοδήματος'],
  ['savings', 'κατάθεση'],
  ['loans', 'οφειλή'],
  ['companies', 'εταιρεία'],
]);

function ToolTipMoreItemsMessage({ hiddenRecords, visualisedVar }) {
  if (hiddenRecords <= 0) {
    return <p></p>;
  }

  if (hiddenRecords === 1) {
    return (
      <p>
        {hiddenRecords} {objectsMapSingular.get(visualisedVar)} ακόμη, click για
        περισσότερα
      </p>
    );
  }

  return (
    <p>
      {hiddenRecords} {objectsMapPlural.get(visualisedVar)} ακόμη, click για
      περισσότερα
    </p>
  );
}

function ToolTipBar(props) {
  const {
    politician,
    dataMap,
    visualisedVar,
    secondVisualisedVar,
    total,
    party,
  } = props;

  if (!politician.extra) {
    return null;
  }

  return (
    <div>
      <DataBarContainer>
        <DataBarsRow>
          <DataBarsCell>Σύνολο</DataBarsCell>
          <DataBarsNumCell>
            {politician.extra
              ? formatNumber(politician.extra['sum'][secondVisualisedVar])
              : ''}
          </DataBarsNumCell>
        </DataBarsRow>
      </DataBarContainer>
      {visualisedVar !== 'investments'
        ? dataMap
            .filter((obj) => obj !== 'sum')
            .map((item, index) => (
              <DataBarContainer key={index}>
                <DataBarsRow>
                  <DataBarsCell>{item}</DataBarsCell>
                  <DataBarsNumCell>
                    {formatNumber(politician.extra[item][secondVisualisedVar])}
                  </DataBarsNumCell>
                </DataBarsRow>
                <DataBar>
                  <DataInnerBar
                    party={party}
                    mainBar={index === 1 ? true : false}
                    style={{
                      width: `${
                        (politician.extra[item][secondVisualisedVar] * 100) /
                        total
                      }%`,
                    }}
                  ></DataInnerBar>
                </DataBar>
              </DataBarContainer>
            ))
        : politician.extra.records
            .sort((a, z) => z[secondVisualisedVar] - a[secondVisualisedVar])
            .slice(0, 6)
            .map((record, index) => {
              return (
                <DataBarContainer key={index}>
                  <DataBarsRow>
                    <DataBarsCell>{record.key}</DataBarsCell>
                    <DataBarsNumCell>
                      {formatNumber(record[secondVisualisedVar])}
                    </DataBarsNumCell>
                  </DataBarsRow>
                  <DataBar>
                    <DataInnerBar
                      party={party}
                      mainBar={index === 1 ? true : false}
                      style={{
                        width: `${
                          (record[secondVisualisedVar] * 100) / total
                        }%`,
                      }}
                    ></DataInnerBar>
                  </DataBar>
                </DataBarContainer>
              );
            })}
    </div>
  );
}

function Tooltip(props) {
  const politician = useSelector((state) => {
    return state.politicians.hovered;
  });
  const dispatch = useDispatch();

  const noData = Object.keys(politician).length <= 4 ? true : false;

  const { fullName, party, ttLeft, ttTop, photo, xPos, yPos, color } =
    politician;
  const fullNameArray = fullName?.trim().split(' ');
  const name = fullNameArray ? fullNameArray[0] : '';
  const surname = fullNameArray
    ? fullNameArray.length > 2
      ? fullNameArray[fullNameArray.length - 1]
      : fullNameArray[1]
    : '';
  const tipOnTop = ttTop < yPos ? true : false;
  const { tooltipType, visualisedVar, secondVisualisedVar } = props;
  const total = noData ? 0 : politician.extra.sum[secondVisualisedVar];
  const sorted_object = noData
    ? []
    : Object.fromEntries(
        Object.entries(politician.extra).sort(
          ([, v1], [, v2]) => v2[secondVisualisedVar] - v1[secondVisualisedVar]
        )
      );

  //FILTER TO SHOW ONLY LAND OR BUILDING THAT HAS VALUES AND LOANS THAT HAS PENDING
  const filteredObj = Object.entries(sorted_object)
    .filter((obj) => obj[1][secondVisualisedVar] && obj[0] !== 'sum')
    .reduce((accum, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
  const totalAmount = Object.values(filteredObj).reduce(
    (a, b) => a + b.amount,
    0
  );

  const dataMap = noData
    ? []
    : visualisedVar === 'lands' || visualisedVar === 'loans'
    ? Object.keys(filteredObj)
    : Object.keys(sorted_object).slice(0, 7);

  let hiddenObjects = Object.keys(sorted_object).length - 7;
  if (visualisedVar === 'investments') {
    hiddenObjects = politician.extra ? politician.extra.records.length - 6 : 0;
  }

  return (
    <Wrapper
      noData={noData}
      onMouseOver={() => {
        clearTimeout(props.timerRef.current);
      }}
      onMouseOut={() => {
        props.mouseoutTimer();
      }}
    >
      <Tip id="tooltip" tipX={ttLeft} tipY={ttTop}>
        <TipContainer>
          {tooltipType === 'Table' ? (
            <DataTable>
              <thead>
                <DataTableRow>
                  <DataTableHeadCell></DataTableHeadCell>
                  <DataTableHeadCell>
                    {visualisedVar === 'lands' ? 'Αριθμός' : ''}
                  </DataTableHeadCell>
                  <DataTableHeadCell>
                    {visualisedVar === 'lands' ? 'τμ.' : ''}
                  </DataTableHeadCell>
                </DataTableRow>
              </thead>
              <tbody>
                <DataTableRow>
                  <DataTableCell>Σύνολο</DataTableCell>
                  <DataTableNumCell>
                    {visualisedVar === 'lands'
                      ? politician.extra
                        ? totalAmount
                        : ''
                      : ''}
                  </DataTableNumCell>
                  <DataTableNumCell>
                    {politician.extra
                      ? politician.extra['sum'][secondVisualisedVar]
                      : ''}
                  </DataTableNumCell>
                </DataTableRow>
                {dataMap
                  .filter((obj) => obj !== 'sum')
                  .map((d, i) => (
                    <DataTableRow key={i}>
                      <DataTableCell>{d}</DataTableCell>
                      <DataTableNumCell>
                        {politician.extra[d].amount}
                      </DataTableNumCell>
                      <DataTableNumCell>
                        {visualisedVar === 'lands'
                          ? politician.extra[d][secondVisualisedVar]
                          : formatNumber(politician.extra[d].fund_now)}
                      </DataTableNumCell>
                    </DataTableRow>
                  ))}
              </tbody>
            </DataTable>
          ) : (
            <ToolTipBar
              politician={politician}
              dataMap={dataMap}
              visualisedVar={visualisedVar}
              secondVisualisedVar={secondVisualisedVar}
              total={total}
              party={party}
            />
          )}

          <ToolTipMoreItemsMessage
            hiddenRecords={hiddenObjects}
            visualisedVar={visualisedVar}
          />
        </TipContainer>
      </Tip>

      <TipHead xPos={xPos} yPos={yPos}>
        <Name color={color} tipOnTop={tipOnTop}>
          <p>{name ? normalizeGreek(name) : ''}</p>
          <p>{surname ? normalizeGreek(surname) : ''}</p>
        </Name>
        <PoliticianImg
          image={
            photo === ''
              ? `${API_URL}/uploads/missing_photo.png`
              : `${API_URL}${photo}`
          }
          color={color}
          xPos={xPos}
          yPos={yPos}
        />
      </TipHead>
    </Wrapper>
  );
}

Tooltip.propTypes = {
  visualisedVar: PropTypes.string,
  TooltipType: PropTypes.string,
};

export default Tooltip;
