import { SET_YEAR, RESET_YEAR } from '../constants/actionTypes';

export const setYear = (payload) => ({
  type: SET_YEAR,
  payload,
});

export const resetYear = () => ({
  type: RESET_YEAR,
});
