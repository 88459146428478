import React, { useSelector } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from '../api';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as colors from '../Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '350px',
    maxWidth: '450px',
  },
  card: {
    width: '100%',
    backgroundColor: '#fddc6c',
  },
  typography: {
    fontWeight: 'normal',
    color: `${colors.app.black}`,
  },
  actionRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 8px 8px 16px',
  },
  icons: {
    marginLeft: 'auto',
  },
  imgCont: {
    marginRight: '10px',
    display: 'flex',
  },
  img: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const pic = props.politicianPhoto
    ? `${API_URL}${props.politicianPhoto}`
    : 'https://via.placeholder.com/150';
  return (
    <div ref={ref} className={classes.root}>
      <div className={classes.imgCont}>
        <img className={classes.img} src={pic} alt={props.politicianName} />
      </div>
      <Typography variant="subtitle2" className={classes.typography}>
        {props.message}
      </Typography>
      <div className={classes.icons}>
        <IconButton
          className={classes.expand}
          onClick={() => props.handleDismiss(props.id)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
});

export default SnackMessage;
