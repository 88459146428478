import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as colors from '../Colors';

const MenuItem = styled.div`
  color: ${(props) =>
    props.isSelected ? colors.app.secondary : colors.app.black};
  -webkit-text-stroke: ${(props) =>
    props.isSelected ? `1px ${colors.app.secondary}` : '0px'};
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  height: 14px;
  position: relative;
  &:hover {
    color: ${colors.app.secondary};
  }
  cursor: pointer;
  &::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

const Notification = styled.span`
  background-color: ${colors.app.orange};
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  margin-left: 2px;
  width: 17px;
  height: 17px;
  text-align: center;
  -webkit-text-stroke: initial;
`;

function MenuButton({ label, visVar, notifications, onClick, isSelected }) {
  const [notificationNumber, setNotificationNumber] = useState(0);
  useEffect(() => {
    setNotificationNumber(notifications);
  }, [notifications]);
  return (
    <MenuItem isSelected={isSelected} onClick={onClick}>
      {label}
      {notifications > 0 ? (
        <Notification>{notificationNumber}</Notification>
      ) : (
        ''
      )}
    </MenuItem>
  );
}

MenuButton.propTypes = {
  label: PropTypes.string,
  visVar: PropTypes.string,
  notifications: PropTypes.number,
  isSelected: PropTypes.bool,
};

export default MenuButton;
