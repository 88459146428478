/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';

export default function NotFound() {
  return (
    <main style={{ minHeight: 600, padding: 16 }}>
      <h3>Not what you are searching for</h3>
    </main>
  );
}
