import {
  INIT_FILTERS,
  SET_FILTERS,
  RESET_FILTERS,
  RESET_FILTERS_LOCATION_CHANGED,
} from '../constants/actionTypes';
import { yearData } from '../constants';

const initialState = {
  data: {
    year: yearData[yearData.length - 1],
    parties: {
      1: true,
      2: true,
      3: true,
      4: true,
      7: true,
      10: true,
    },
    politician: undefined,
    politicianYear: undefined,
    selected: [537, 858, 35, 414, 995, 73],
    visualisedType: 'sum',
    range: {
      min: undefined,
      max: undefined,
    },
  },
  urlSet: false,
  init: false,
};

export default function yearReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        init: true,
      };
    case SET_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        urlSet: true,
      };
    case RESET_FILTERS:
      const selected = state.data.selected;

      return {
        ...state,
        data: {
          ...initialState.data,
          selected,
        },
        urlSet: true,
      };
    case RESET_FILTERS_LOCATION_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          range: initialState.data.range,
          visualisedType: initialState.data.visualisedType,
        },
        urlSet: true,
      };
    default:
      return state;
  }
}
