import { select } from 'd3-selection';
// import { timeFormatDefaultLocale } from 'd3-time-format';
import { format, formatDefaultLocale } from 'd3-format';

/* export function debounce(fn, wait) {
  let t;
  return function () {
    clearTimeout(t);
    t = setTimeout(() => fn.apply(this, arguments), wait);
  };
} */

export function blink(selector) {
  selector
    .transition()
    .duration(1000)
    .style('opacity', 0.3)
    .transition()
    .duration(1000)
    .style('opacity', 1)
    .on('end', () => blink(selector));
}

//LINES BREAK FUNCTION
export function wrap(text, width) {
  text.each(function () {
    var text = select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.3, // ems
      y = text.attr('y'),
      x = text.attr('x'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em');
    while ((word = words.pop())) {
      // jshint ignore:line
      line.push(word);
      tspan.text(line.join(' '));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    }
  });
}

export const grGR = {
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['', '€'],
  dateTime: '%a %b %e %X %Y',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['ΠΜ', 'ΜΜ'],
  days: [
    'Κυριακή',
    'Δευτέρα',
    'Τρίτη',
    'Τετάρτη',
    'Πέμπτη',
    'Παρασκευή',
    'Σάββατο',
  ],
  shortDays: ['Κυρ', 'Δευτ', 'Τρ', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
  months: [
    'Ιανουάριος',
    'Φεβρουάριος',
    'Μάρτιος',
    'Απρίλιος',
    'Μάϊος',
    'Ιούνιος',
    'Ιούλιος',
    'Αύγουστος',
    'Σεπτέμβριος',
    'Οκτώβριος',
    'Νοέμβριος',
    'Δεκέμβριος',
  ],
  shortMonths: [
    'Ιαν',
    'Φεβ',
    'Μαρ',
    'Απρ',
    'Μάι',
    'Ιουν',
    'Ιουλ',
    'Αυγ',
    'Σεπ',
    'Οκτ',
    'Νοε',
    'Δεκ',
  ],
};

formatDefaultLocale(grGR);
export const formatNumber = format('$,.0f');

export const formatDecimal = format(',.1f');

export const formatNumberNoSymb = format(',.0f');

export const formatThousandDecimal = format(',.2f');

export const sum = (array, key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
};

export const normalizeGreek = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getInvestmentsTotal = (records, visualisedVar, sumTotal) => {
  const sumOfItems = records.reduce((accumulator, record) => {
    if (record.key === visualisedVar) {
      return accumulator + record[sumTotal];
    }

    return accumulator;
  }, 0);

  return sumOfItems;
};
export const getTotal = (item, visualisedVar, sumTotal) => {
  if (item.extra.records && visualisedVar !== 'sum') {
    return getInvestmentsTotal(item.extra.records, visualisedVar, sumTotal);
  }

  return item.extra[visualisedVar][sumTotal];
};
