import React, { useEffect, useState } from 'react';
import SearchField from './SearchField';
import DropDownMenuDefault from './DropDownMenuDefault';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const SearchItems = (props) => {
  let { items, action, reset, label } = props;
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dropItems, setDropItems] = useState([]);
  let dropdownRef = React.createRef();

  useEffect(() => {
    if (reset === false) resetSearchField();
    setDropItems(items);
  }, [items, reset]);

  const setSelection = (key) => {
    setSelected(key);
  };

  const resetSearchField = () => {
    setSelected(null);
  };

  const typeHandler = (e) => {
    const filteredData = items.filter((obj) =>
      obj.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setOpened(true);
    setSelection(e.target.value);
    setDropItems(filteredData);
  };

  function handleClick(e) {
    if (dropdownRef.current.contains(e.target)) {
      setOpened(!opened);
    } else {
      setOpened(false);
    }
  }

  const handleClickAway = () => {
    setOpened(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div onClick={handleClick} ref={dropdownRef}>
        <div>
          <SearchField
            isMainSearch={false}
            label={label}
            typeHandler={typeHandler}
            value={selected}
          />
        </div>
        <DropDownMenuDefault
          width={210}
          isOpen={opened}
          items={dropItems}
          handleClck={handleClick}
          handleSelection={setSelection}
          actionToDispatch={action}
        ></DropDownMenuDefault>
      </div>
    </ClickAwayListener>
  );
};

export default SearchItems;
