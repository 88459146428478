import * as colors from '../Colors';
import { scaleTime, scaleSqrt } from 'd3-scale';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';
import { min, max, descending } from 'd3-array';
import { wrap, grGR, formatNumber } from '../utils/Helpers';
import { timeFormat, timeFormatDefaultLocale } from 'd3-time-format';
import { timeYear } from 'd3-time';
import {
  annotation,
  annotationCalloutElbow,
  annotationCustomType,
} from 'd3-svg-annotation';
import { bboxCollide } from 'd3-bboxCollide';
import { forceSimulation, forceX, forceY } from 'd3-force';

class BubbleChart {
  defaultProps = {
    grey: '#666666',
    lightGrey: '#f5f5f5',
    label: "16px 'PF Din Text'",
  };

  // Selected root element of the chart
  selection(selector) {
    if (!selector) return this._selection;
    this._selection = select(selector);
    return this;
  }

  // Display props like colors and arbitrary data
  props(obj) {
    if (!obj) return this._props || this.defaultProps;

    this._props = { ...obj, ...this.defaultProps };
    return this;
  }

  // Chart data!
  data(arr) {
    if (!arr) return this._data || this.defaultData;

    this._data = arr;
    return this;
  }

  draw() {
    const data = this.data().sort(function (x, y) {
      return descending(x['pending_amount'], y['pending_amount']);
    });
    const props = this.props();

    const node = this.selection().node();
    let { width, height } = node.getBoundingClientRect();
    const margin = { top: 20, right: 40, bottom: 20, left: 20 };
    width = width - (margin.left + margin.right);
    height = props.height - (margin.top + margin.bottom);

    const minDate = min(data, (d) => new Date(d['start_date']));
    const domainMinDate = minDate.setFullYear(minDate.getFullYear() - 2);
    const maxDate = max(data, (d) => new Date(d['start_date']));

    //const domainMaxDate = maxDate.setFullYear( maxDate.getFullYear() + 4 );
    timeFormatDefaultLocale(grGR);

    const formatDate = timeFormat('%B %Y');

    const showTooltip = function (d, i) {
      //select(this.parentNode).attr("data-overlapped", (d, i) => isOverlapping(d,i) ? true : false);
      svg
        .selectAll('.annotation')
        .transition()
        .duration(150)
        .style('opacity', 0);

      svg
        .select(`.loan_annot_${d.id}`)
        .transition()
        .duration(150)
        .style('opacity', 1);

      svg.selectAll('.loan').transition().duration(150).style('opacity', 0.3);

      select(this).transition().duration(150).style('opacity', 1);

      select(this.parentNode)
        .selectAll('.loan-line')
        .transition()
        .duration(200)
        .style('opacity', 1);
      select(this.parentNode)
        .selectAll('text')
        .transition()
        .duration(200)
        .style('opacity', 1);
    };

    const hideTooltip = function (d, i) {
      svg
        .selectAll('.annotation')
        .transition()
        .duration(150)
        .style('opacity', 1);

      svg.selectAll('.loan').transition().duration(150).style('opacity', 1);

      select(this.parentNode)
        .selectAll('.loan-line')
        .transition()
        .duration(200)
        .style('opacity', 0);
      select(this.parentNode)
        .selectAll('.loan-date')
        .transition()
        .duration(200)
        .style('opacity', 0);
    };

    const x = scaleTime()
      .domain([timeYear.floor(domainMinDate), timeYear.ceil(maxDate)])
      .range([margin.left, width + margin.left]);

    const rScale = scaleSqrt()
      .domain([1000, max(data, (d) => d.pending_amount)])
      .nice()
      .range([2, 60]);

    const axis = axisBottom(x); //.tickSize(height-margin.top/2);

    const type = annotationCustomType(annotationCalloutElbow, {
      connector: { type: 'elbow', end: 'dot' },
    });

    const makeAnnotations = annotation()
      //.editMode(true)
      //also can set and override in the note.padding property
      //of the annotation object
      .textWrap(80)
      .notePadding(2)
      .type(type)
      .annotations(
        data.map((d, i) => {
          return {
            data: {
              x: x(new Date(d['start_date'])),
              y: height / 2,
            },
            note: {
              title: formatNumber(d.pending_amount),
              label: d.creditor,
              lineType: 'horizontal',
              align: 'dynamic',
            },
            className: `loan_annot_${d.id}`,
          };
        })
      )
      //accessors & accessorsInverse not needed
      //if using x, y in annotations JSON
      .accessors({
        x: (d) => d.x,
        y: (d) => d.y,
      });
    /*.accessorsInverse({
         date: d => timeFormat(x.invert(d.x)),
         close: d => y.invert(d.y)
      })*/

    const svg = this.selection()
      .appendSelect('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    const loans = svg.appendSelect('g', 'loansGroup');
    loans.selectAll('g').remove();
    //loans.call(makeAnnotations);

    const loanGroup = loans.selectAll('.loanG').data(data).enter().append('g');

    loanGroup
      .append('circle')
      .attr('class', 'loan')
      .attr('id', (d, i) => `loan${i + 1}`)
      .attr('cx', (d) => x(new Date(d['start_date'])))
      .attr('cy', height / 2)
      .attr('r', (d) => rScale(d.pending_amount))
      .style('fill', 'transparent')
      .style('stroke', colors.app.secondary)
      .style('stroke-width', '2px')
      .on('mouseover', showTooltip)
      .on('mouseout', hideTooltip);

    loanGroup
      .append('line')
      .attr('class', 'loan-line')
      .attr('x1', (d) => x(new Date(d['start_date'])))
      .attr('x2', (d) => x(new Date(d['start_date'])))
      .attr('y1', (d) => height / 2 + rScale(d.pending_amount))
      .attr('y2', height)
      .style('stroke', colors.app.darkGrey)
      .style('stroke-dasharray', '2,2')
      .style('opacity', 0);

    loanGroup
      .append('text')
      .attr('class', 'loan-date')
      .attr('x', function (d) {
        return x(new Date(d['start_date'])) +
          5 +
          this.getComputedTextLength() >=
          width
          ? x(new Date(d['start_date'])) - 5
          : x(new Date(d['start_date'])) + 5;
      })
      .attr('y', (d) => (height / 2 + rScale(d.pending_amount) + height) / 2)
      .attr('text-anchor', function (d) {
        return x(new Date(d['start_date'])) +
          5 +
          this.getComputedTextLength() >=
          width
          ? 'end'
          : 'start';
      })
      .text((d) => formatDate(new Date(d['start_date'])))
      .style('font-size', '14px')
      .style('opacity', 0)
      .attr('data-width', function () {
        return;
      })
      .attr('dy', '.45em')
      .call(wrap, 60);

    svg
      .appendSelect('g', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .transition()
      .duration(1000)
      .call(axis);

    svg.appendSelect('g', 'annotation-test').call(makeAnnotations);

    const noteBoxes = makeAnnotations.collection().noteNodes;
    const simulation = forceSimulation(noteBoxes)
      .force(
        'x',
        forceX((a) => a.positionX).strength((a) =>
          Math.max(0.25, Math.min(3, Math.abs(a.x - a.positionX) / 20))
        )
      )
      .force(
        'y',
        forceY((a) => a.positionY).strength((a) =>
          Math.max(0.25, Math.min(3, Math.abs(a.x - a.positionX) / 20))
        )
      )
      .force(
        'collide',
        bboxCollide((a) => {
          return [
            [a.offsetCornerX - 5, a.offsetCornerY - 15],
            [a.offsetCornerX + a.width + 5, a.offsetCornerY + a.height + 15],
          ];
        })
          .strength(1)
          .iterations(1)
      )
      .alpha(0.1)
      .alphaDecay(0.1)
      .alphaMin(0.01)
      .on('tick', () => {
        makeAnnotations.annotations().forEach((d, i) => {
          const match = noteBoxes[i];
          match.x = Math.max(
            match.width,
            Math.min(width - match.width, match.x)
          );
          match.y = Math.max(
            match.height,
            Math.min(height - match.height, match.y)
          );
          d.dx = match.x - match.positionX;
          d.dy = match.y - match.positionY;
        });
        makeAnnotations.update();
      });
  }
}

export default BubbleChart;
