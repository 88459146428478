export const GET_POLITICIANS = 'GET_POLITICIANS';
export const GET_POLITICIANS_PENDING = 'GET_POLITICIANS_PENDING';
export const GET_POLITICIANS_FULFILLED = 'GET_POLITICIANS_FULFILLED';
export const GET_POLITICIANS_REJECTED = 'GET_POLITICIANS_REJECTED';

export const GET_POLITICIAN = 'GET_POLITICIAN';
export const GET_POLITICIAN_PENDING = 'GET_POLITICIAN_PENDING';
export const GET_POLITICIAN_FULFILLED = 'GET_POLITICIAN_FULFILLED';
export const GET_POLITICIAN_REJECTED = 'GET_POLITICIAN_REJECTED';

export const ADD_REMOVE_POLITICIAN = 'ADD_REMOVE_POLITICIAN';
export const SEARCH_POLITICIANS = 'SEARCH_POLITICIANS';
export const HOVERED_POLITICIAN = 'HOVERED_POLITICIAN';
export const CLICKED_POLITICIAN = 'CLICKED_POLITICIAN';

export const GET_INCOME = 'GET_INCOME';
export const GET_INCOME_PENDING = 'GET_INCOME_PENDING';
export const GET_INCOME_FULFILLED = 'GET_INCOME_FULFILLED';
export const GET_INCOME_REJECTED = 'GET_INCOME_REJECTED';

export const SET_SELECTED_PARTIES = 'SET_SELECTED_PARTIES';
export const ADD_REMOVE_PARTY = 'ADD_REMOVE_PARTY';
export const RESET_PARTY = 'RESET_PARTY';

export const GET_SAVINGS = 'GET_SAVINGS';
export const GET_SAVINGS_PENDING = 'GET_SAVINGS_PENDING';
export const GET_SAVINGS_FULFILLED = 'GET_SAVINGS_FULFILLED';
export const GET_SAVINGS_REJECTED = 'GET_SAVINGS_REJECTED';

export const GET_LOANS = 'GET_LOANS';
export const GET_LOANS_PENDING = 'GET_LOANS_PENDING';
export const GET_LOANS_FULFILLED = 'GET_LOANS_FULFILLED';
export const GET_LOANS_REJECTED = 'GET_LOANS_REJECTED';

export const GET_INVESTMENTS = 'GET_INVESTMENTS';
export const GET_INVESTMENTS_PENDING = 'GET_INVESTMENTS_PENDING';
export const GET_INVESTMENTS_FULFILLED = 'GET_INVESTMENTS_FULFILLED';
export const GET_INVESTMENTS_REJECTED = 'GET_INVESTMENTS_REJECTED';

export const GET_LANDS = 'GET_LANDS';
export const GET_LANDS_PENDING = 'GET_LANDS_PENDING';
export const GET_LANDS_FULFILLED = 'GET_LANDS_FULFILLED';
export const GET_LANDS_REJECTED = 'GET_LANDS_REJECTED';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_PENDING = 'GET_COMPANIES_PENDING';
export const GET_COMPANIES_FULFILLED = 'GET_COMPANIES_FULFILLED';
export const GET_COMPANIES_REJECTED = 'GET_COMPANIES_REJECTED';

export const SET_YEAR = 'SET_YEAR';
export const RESET_YEAR = 'RESET_YEAR';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_PENDING = 'GET_NOTIFICATIONS_PENDING';
export const GET_NOTIFICATIONS_FULFILLED = 'GET_NOTIFICATIONS_FULFILLED';
export const GET_NOTIFICATIONS_REJECTED = 'GET_NOTIFICATIONS_REJECTED';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_POLITICIAN_ALL_NOTIFICATIONS =
  'REMOVE_POLITICIAN_ALL_NOTIFICATIONS';

export const SET_FILTERS = 'SET_FILTERS';
export const INIT_FILTERS = 'INIT_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_FILTERS_LOCATION_CHANGED = 'RESET_FILTERS_LOCATION_CHANGED';
