import { SET_YEAR, RESET_YEAR } from '../constants/actionTypes';
import { yearData } from '../constants';

const initialState = { all: yearData, selected: yearData[yearData.length - 1] };

export default function yearReducer(state = initialState, action) {
  switch (action.type) {
    case SET_YEAR:
      const selectedYear = action.payload;

      return { ...state, selected: selectedYear };
    case RESET_YEAR:
      return { ...state, selected: initialState.selected };
    default:
      return state;
  }
}
