import * as colors from '../Colors';
import { scaleBand, scalePow, scaleLinear } from 'd3-scale';
import { extent, ascending } from 'd3-array';
import { forceSimulation, forceX, forceY, forceCollide } from 'd3-force';
import { select, create } from 'd3-selection';
import { nest } from 'd3-collection';
import { transition } from 'd3-transition';
import { axisTop } from 'd3-axis';
import { blink, wrap, getTotal } from '../utils/Helpers';
import { selection } from '../utils/Selection';
import { variableBand } from '../utils/VariableBand';

class BeeswarmChart {
  defaultProps = {
    grey: '#666666',
    lightGrey: '#f5f5f5',
    label: "16px 'Source Sans Pro'",
  };

  // Selected root element of the chart
  selection(selector) {
    if (!selector) return this._selection;
    this._selection = select(selector);
    return this;
  }

  // Display props like colors and arbitrary data
  props(obj) {
    if (!obj) return this._props || this.defaultProps;

    this._props = Object.assign(obj, this.defaultProps);
    return this;
  }

  // Chart data!
  data(arr) {
    if (!arr) return this._data || this.defaultData;

    this._data = arr;
    return this;
  }

  draw() {
    const dataset = this.data();
    const props = this.props();

    const data = dataset.filter(function (obj) {
      //investments
      if (obj.extra.records && props.visualisedVar !== 'sum') {
        const hasKey = obj.extra.records.some(
          (record) => record.key === props.visualisedVar
        );

        return hasKey && props.selectedPartiesData[obj.party] === true;
      } else {
        return (
          props.selectedPartiesData[obj.party] === true &&
          obj.extra[props.visualisedVar] !== undefined
        );
      }
    });

    const politiciansByParty = nest()
      .key((d) => d.party)
      .sortKeys(ascending)
      .entries(data);
    politiciansByParty.sort(function (a, b) {
      return a.values.length - b.values.length;
    });
    const sumTotal = props.secondVisualisedVar
      ? props.secondVisualisedVar
      : 'total';

    const node = this.selection().node();
    let { width, height } = node.getBoundingClientRect();

    const margin = { top: 20, right: 40, bottom: 0, left: 140 };
    width = width - (margin.left + margin.right);
    height = height - (margin.top + margin.bottom);

    const t = transition().duration(1000);
    const radiusTransition = transition().duration(200);

    const x = scalePow()
      .exponent(0.7)
      .domain(
        extent(data, (dataItem) => {
          return getTotal(dataItem, props.visualisedVar, sumTotal);
        })
      )
      .range([margin.left, width + margin.left]);

    const partiesLengthMap = new Map(
      politiciansByParty.map((d) => [d.key, d.values.length > 50 ? 100 : 30])
    );
    const yRange =
      partiesLengthMap.size > 1 ? [height, 0] : [height / 3, margin.top];
    const axisOpacity = data.length > 1 ? 1 : 0;
    const y = variableBand()
      .domain(politiciansByParty.map((d) => d.key))
      .weights((d) => partiesLengthMap.get(d))
      .range(yRange)
      .paddingInner(0.02)
      .align(height);

    const axis = axisTop(x).tickSize(height - margin.top / 2);

    const nodes = data.map(function (item) {
      const party =
        item.party === 0
          ? 'Αλλο'
          : props.partiesData.filter((obj) => item.party === obj.id)[0];

      const partyName = party ? party.Abbreviation : '';
      const isSelected = props.selectedPoliticians.includes(item.id);

      return {
        ...item,
        radius: isSelected ? 7 : 4,
        color: colors.parties[item.party],
        // x: x(item.extra[props.visualisedVar][sumTotal]),
        x: x(getTotal(item, props.visualisedVar, sumTotal)),
        y:
          y(item.party.toString()) +
          Math.round(y.bandwidth(item.party.toString()) / 2),
        partyName: partyName,
      };
    });

    const nodesByParty = nest()
      .key((d) => d.party)
      .sortKeys(ascending)
      .entries(nodes);
    nodesByParty.sort(function (a, b) {
      return a.values.length - b.values.length;
    });

    const force = forceSimulation(nodes)
      //.velocityDecay(0.2)
      .alpha(1)
      .force('forceX', forceX((d) => d.x).strength(2.1))
      .force('forceY', forceY((d) => d.y).strength(2.1))
      .force(
        'collide',
        forceCollide((d) => {
          const isSelected = props.selectedPoliticians.includes(d.id);

          return d.radius + (isSelected ? 4 : 0.3);
        })
      )
      .stop();

    force.tick(300);

    const svg = this.selection()
      .appendSelect('svg', 'chartSvg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top / 2);

    //Loader
    const loading = svg.appendSelect('g', 'loadingSvg');
    loading
      .selectAll('.loadingRects')
      .data([1, 2, 3, 4])
      .enter()
      .appendSelect('rect', 'loadingRects')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height / 4)
      .attr('y', (d, i) => (height / 4) * i + 7 * i)
      .style('fill', colors.app.lightGrey);

    loading
      .selectAll('.loadingNames')
      .data([1, 2, 3, 4])
      .enter()
      .appendSelect('rect', 'loadingNames')
      .attr('width', 60)
      .attr('height', 20)
      .attr('x', width / 11)
      .attr('y', (d, i) => (height / 4) * i + height / 4 / 2.2)
      .style('fill', colors.app.darkGrey)
      .call(blink);

    const loadingText = loading
      .appendSelect('text', 'messageTxt')
      .attr('x', (width + margin.right + margin.left) / 2)
      .attr('y', (height + margin.top + margin.bottom) / 2)
      .attr('dy', '.45em')
      .attr('text-anchor', 'middle')
      .text(
        'Παρακαλούμε περιμένετε όσο υπολογίζουμε τις περιουσίες των πολιτικών'
      )
      .style('font', props.label)
      .style('fill', '#999')
      .call(wrap, 300);

    const partyGroups = svg.appendSelect('g', 'partyGroups');

    svg
      .appendSelect('g', 'x axis')
      .attr('transform', 'translate(0,' + (height + 2) + ')')
      .attr('opacity', axisOpacity)
      .transition()
      .duration(1000)
      .call(axis);

    (function renderGraph() {
      const partyGroup = partyGroups
        .selectAll('.partyGroup')
        .data(nodesByParty, (d) => d.key);
      partyGroup
        .join(
          (enter) =>
            enter
              .append('g')
              .attr('class', 'partyGroup')
              .attr('data-partyName', (d) => d.values[0].partyName)
              .call((enter) =>
                enter
                  .append('rect')
                  .attr('x', 0)
                  .attr('width', width + margin.left + margin.right)
                  .style('fill', colors.app.lightGrey)
                  .attr('y', function (d) {
                    return y(d.key) + y.bandwidth(d.key);
                  })
                  .transition(t)
                  .attr('height', function (d) {
                    return (
                      Math.abs(y.bandwidth(d.key)) - 24 / partiesLengthMap.size
                    );
                  })
              )
              .call((enter) =>
                enter
                  .append('text')
                  .text((d) => d.values[0].partyName)
                  .attr('x', margin.left / 2)
                  .attr('text-anchor', 'middle')
                  .attr('y', function (d) {
                    return y(d.key) + 4;
                  })
                  .attr('dy', function (d) {
                    return Math.round(y.bandwidth(d.key) / 2);
                  })
                  .style('fill', props.grey)
                  .style('font', props.label)
              ),
          (update) =>
            update
              .call((update) =>
                update
                  .select('rect')
                  .transition()
                  .duration(800)
                  .attr('height', function (d) {
                    return (
                      Math.abs(y.bandwidth(d.key)) - 24 / partiesLengthMap.size
                    );
                  })
                  .attr('y', function (d) {
                    return y(d.key) + y.bandwidth(d.key);
                  })
              )
              .call((update) =>
                update
                  .select('text')
                  .transition(t)
                  .attr('y', function (d) {
                    return y(d.key) + 4;
                  })
                  .attr('dy', function (d) {
                    return Math.round(y.bandwidth(d.key) / 2);
                  })
              ),
          (exit) => exit.remove()
        )
        .call((g) =>
          g
            .selectAll('.intRect')
            .data(
              function (d) {
                return d.values;
              },
              (d) => d.id
            )
            .join(
              (enter) =>
                enter
                  .append('rect')
                  .attr('class', 'intRect')
                  .style('fill', 'transparent')
                  .style('cursor', 'pointer')
                  .attr('y', function (d) {
                    return d.y - d.radius * 1.5;
                  })
                  .attr('x', function (d) {
                    return d.x - d.radius * 1.5;
                  })
                  .attr('width', function (d) {
                    return d.radius * 3;
                  })
                  .attr('height', function (d) {
                    return d.radius * 3;
                  })
                  .on('mouseover', function (d) {
                    return props.updateHover(d);
                  })
                  .on('mousemove', function (d) {
                    clearTimeout(props.timerRef.current);
                    const svg = document.getElementById('chart');
                    const xPos = d.x + svg.offsetLeft;
                    const yPos = d.y + svg.offsetTop;
                    const tooltip = document.getElementById('tooltip');
                    const ttWidth = parseInt(
                      select(tooltip).style('width').replace('px', ''),
                      10
                    );
                    const ttHeight = parseInt(
                      select(tooltip).style('height').replace('px', ''),
                      10
                    );
                    let ttLeft = xPos - ttWidth / 2 - 1;
                    let ttTop = yPos - ttHeight - 88;

                    const maxRight =
                      document.body.clientWidth -
                      svg.offsetLeft -
                      ttWidth / 2 -
                      70;

                    if (ttLeft + ttWidth / 2 >= maxRight) {
                      ttLeft = maxRight - ttWidth / 2;
                    }

                    if (ttTop < svg.offsetTop - 20) {
                      ttTop = yPos + 25;
                    }

                    if (ttLeft < 0) {
                      ttLeft = 0;
                    }
                    return props.updateHover(d, ttLeft, ttTop, xPos, yPos);
                  })
                  .on('mouseout', () => props.mouseoutTimer())
                  .on('click', (d) => props.updateClicked(d.id)),
              (update) =>
                update
                  .transition(t)
                  .delay(function (d, i) {
                    return i * 10;
                  })
                  .attr('y', function (d) {
                    return d.y - d.radius * 1.5;
                  })
                  .attr('x', function (d) {
                    return d.x - d.radius * 1.5;
                  }),
              (exit) => exit.transition().duration(300).attr('r', 0).remove()
            )
        )
        .call((g) =>
          g
            .selectAll('circle')
            .data(
              function (d) {
                return d.values;
              },
              (d) => d.id
            )
            .join(
              (enter) =>
                enter
                  .append('circle')
                  .style('fill', function (d) {
                    return d.color;
                  })
                  .style('pointer-events', 'none')
                  .style('stroke', (d) => {
                    const isSelected = props.selectedPoliticians.includes(d.id);
                    return isSelected ? 'url(#linear)' : 'transparent';
                  })
                  .style('stroke-width', (d) => {
                    const isSelected = props.selectedPoliticians.includes(d.id);
                    return isSelected ? '3px' : '0px';
                  })
                  .attr('data-name', (d) => `${d.name} ${d.surname}`)
                  .attr('data-party', (d) => d.partyName)
                  .attr('cy', function (d) {
                    return d.y;
                  })
                  .attr('cx', function (d) {
                    return d.x;
                  })
                  .call((enter) =>
                    enter
                      .transition(radiusTransition)
                      .delay(function (d, i) {
                        return i * 5;
                      })
                      .attr('r', function (d) {
                        return d.radius;
                      })
                  ),
              (update) =>
                update
                  .attr('r', function (d) {
                    return d.radius;
                  })
                  .transition(t)
                  .delay(function (d, i) {
                    return i * 10;
                  })
                  .style('stroke', (d) => {
                    const isSelected = props.selectedPoliticians.includes(d.id);
                    return isSelected ? 'url(#linear)' : 'transparent';
                  })
                  .style('stroke-width', (d) => {
                    const isSelected = props.selectedPoliticians.includes(d.id);
                    return isSelected ? '3px' : '0px';
                  })
                  .attr('cy', function (d) {
                    return d.y;
                  })
                  .attr('cx', function (d) {
                    return d.x;
                  }),
              (exit) => exit.transition().duration(300).attr('r', 0).remove()
            )
        );

      if (data.length > 0) {
        loading.remove();
      }
      if (props.dataFetched && data.length === 0) {
        loadingText
          .text(
            'Δεν υπάρχουν δεδομένα για αυτή την επιλογή. Παρακαλούμε επιλέξτε ένα άλλο φίλτρο.'
          )
          .style('font-weight', 900)
          .call(wrap, 300);
        loading.selectAll('.loadingNames').remove();
        loading.selectAll('.loadingRects').remove();
      }
    })();
  }
}

export default BeeswarmChart;
