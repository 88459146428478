import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as colors from '../Colors';
import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';

const StyledTextField = styled(TextField)`
  width: calc(100% - 30px);
  margin-left: 20px;
  margin-right: 10px;
  background-color: ${colors.app.lightSecondary}44!important;
  margin-bottom: 10px;
  input {
    color: ${colors.app.secondary}!important;
  }
  i {
    color: ${colors.app.secondary}!important;
    padding-top: 5px;
  }
  .mdc-notched-outline > div {
    border-color: ${colors.app.secondary}!important;
  }
  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px !important;
    color: ${colors.app.secondary}!important;
    font-weight: bold;
  }
  .mdc-floating-label--float-above {
    color: ${colors.app.secondary}!important;
  }
`;

const FilterTextField = styled(TextField)`
  height: 35px !important;
  width: 250px;
  @media (max-width: 890px) {
    width: 150px;
  }
  margin: 5px 10px 5px 0px;
  background-color: ${colors.app.white}!important;
  padding-bottom: 4px;
  input {
    color: ${colors.app.secondary}!important;
  }
  i {
    color: ${colors.app.secondary}!important;
    padding-top: 0px;
  }
  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px !important;
    color: ${colors.app.black}!important;
    font-weight: bold;
    @media (max-width: 890px) {
      font-size: 14px !important;
    }
  }
  .mdc-line-ripple {
    &:before,
    :after {
      border: 0px;
    }
  }
  .mdc-floating-label--float-above {
    color: ${colors.app.secondary}!important;
  }
`;

function SearchField(props) {
  return props.isMainSearch ? (
    <StyledTextField
      icon="search"
      outlined
      label={props.label}
      onChange={props.typeHandler}
    />
  ) : (
    <FilterTextField
      icon="search"
      label={props.label}
      value={props.value === null ? '' : props.value}
      onChange={props.typeHandler}
    />
  );
}

SearchField.propTypes = {
  label: PropTypes.string,
  typeHandler: PropTypes.func,
  isMainSearch: PropTypes.bool,
};

export default SearchField;
