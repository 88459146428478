import React, { useEffect, useState, useRef } from 'react';
import FilterMenu from '../../components/FilterMenu';
import { fetchIncome } from '../../actions/income';
import { useSelector, useDispatch } from 'react-redux';
import BeeswarmContainer from '../../components/BeeswarmContainer';
import Tooltip from '../../components/Tooltip';
import styled from 'styled-components';
import { resetParty } from '../../actions/parties';
import { resetYear } from '../../actions/year';
import { resetFilters as resetFiltersAction, setFilters } from '../../actions';
import { hoveredPolitician } from '../../actions/politicians';

import { debounce } from 'lodash';
import { pagesDescr } from '../../constants';

const MainStyled = styled.main`
  min-height: 600px;
  padding: 16px;
  padding-bottom: 0px;
  height: calc(100% - 16px);
  display: flex;
  flex-flow: column;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
`;

const Note = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-size: 11px;
`;

const IncomesPage = () => {
  const dispatch = useDispatch();
  const {
    data: { year, visualisedType },
    init,
  } = useSelector((state) => {
    return state.filters;
  });

  useEffect(() => {
    if (init) {
      dispatch(fetchIncome(year));
    }
  }, [dispatch, init, year]);

  const politicians = useSelector((state) => {
    return state.income.politicians;
  });

  const parties = useSelector((state) => {
    return state.income.parties;
  });

  const years = useSelector((state) => {
    return state.year.all;
  });

  const filters = useSelector((state) => {
    return state.income.filters;
  });

  const fetched = useSelector((state) => {
    return state.income.loaded;
  });

  const [visualisedPoliticians, setVisualisedPoliticians] = useState([]);

  const timerRef = useRef(null);
  const hideTip = () => {
    timerRef.current = setTimeout(() => {
      dispatch(hoveredPolitician({}));
    }, 250);
  };

  useEffect(() => {
    setVisualisedPoliticians(politicians);
    return () => clearTimeout(timerRef.current);
  }, [politicians]);

  const updateVisualisedType = (type) => {
    dispatch(
      setFilters({
        visualisedType: type,
      })
    );
    setResetVisible(true);
  };

  const sliderFilter = (min, max) => {
    const filtered = politicians.filter((politician) => {
      return (
        politician.extra[visualisedType] &&
        politician.extra[visualisedType]['total'] >= min &&
        politician.extra[visualisedType]['total'] <= max
      );
    });

    setVisualisedPoliticians(filtered);
  };

  const debouncedSliderFilter = debounce(sliderFilter, 300);

  const [resetVisible, setResetVisible] = useState(false);
  const resetState = (bool) => {
    setResetVisible(bool);
  };

  const resetFilters = () => {
    setResetVisible(false);
    dispatch(resetYear());
    dispatch(resetParty());
    dispatch(resetFiltersAction());
    setVisualisedPoliticians(politicians);
  };

  return (
    <MainStyled>
      <FilterMenu
        visualisedVar="incomes"
        partiesData={parties}
        itemsData={filters}
        yearsData={years}
        actionToDispatch={updateVisualisedType}
        visualisedType={visualisedType}
        resetState={resetState}
        reset={resetVisible}
        resetAction={resetFilters}
        sliderAction={debouncedSliderFilter}
      />
      <Description>{pagesDescr.incomes}</Description>
      <Note>(Ποσά σε €, άθροισμα βουλευτών και συζύγων)</Note>
      <BeeswarmContainer
        dataset={visualisedPoliticians}
        partiesData={parties}
        visualisedVar={visualisedType}
        fetched={fetched}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      ></BeeswarmContainer>
      <Tooltip
        visualisedVar="incomes"
        tooltipType="Bars"
        secondVisualisedVar={'total'}
        mouseoutTimer={hideTip}
        timerRef={timerRef}
      />
    </MainStyled>
  );
};

export default IncomesPage;
