export const pagesDescr = {
  incomes:
    'Έσοδα από κάθε πηγή που αποκτήθηκαν κατά το προηγούμενο φορολογικό έτος',
  savings:
    'Καταθέσεις σε τράπεζες, ταμιευτήρια και άλλα ημεδαπά ή αλλοδαπά πιστωτικά ιδρύματα',
  investments:
    'Μετοχές εταιρειών, ομόλογα, αμοιβαία κεφάλαια καθώς και οποιοδήποτε χρηματοοικονομικό προϊόν',
  lands:
    'Ακίνητα και εμπράγματα δικαιώματα επί αυτών, στην Ελλάδα ή στο εξωτερικό',
  loans: 'Δανειακές υποχρεώσεις και οφειλές',
  companies: 'Συμμετοχές σε κάθε είδους επιχείρηση',
};

export const incomeTypes = [
  'Μερίσματα, τόκοι, δικαιώματα',
  'Συνολικο Καθαρο Εισοδημα',
  'Αλλοδαπης Προελευσης',
  'Εισοδημα Απο Επιχειρηματικη Δραστηριοτητα',
];

export const yearData = [2016, 2017, 2018, 2019, 2020, 2021, 2022];

export const preSelectedParties = new Set([1, 2, 3, 4, 7, 10]); //party ids

export const districts = new Map([
  [21, 'Α΄ Αθηνών'],
  [60, 'Α΄ Ανατολικής Αττικής'],
  [5, 'Α΄ Θεσσαλονίκης'],
  [30, 'Α΄ Πειραιά'],
  [20, 'Αιτωλίας και Ακαρνανίας'],
  [2, 'Ανατολικής Αττικής'],
  [9, 'Αργολίδος'],
  [24, 'Αρκαδίας'],
  [31, 'Άρτης'],
  [25, 'Αττικής'],
  [7, 'Αχαΐας'],
  [6, 'Β΄ Αθηνών'],
  [63, 'Β΄ Δυτικής Αττικής'],
  [8, 'Β΄ Θεσσαλονίκης'],
  [23, 'Β΄ Πειραιά'],
  [62, 'Β1΄ Βόρειου Τομέα Αθηνών'],
  [59, 'Β2΄ Δυτικού Τομέα Αθηνών'],
  [61, 'Β3΄ Νότιου Τομέα Αθηνών'],
  [43, 'Βοιωτίας'],
  [57, 'Γρεβενών'],
  [47, 'Δράμας'],
  [29, 'Δωδεκανήσου'],
  [37, 'Έβρου'],
  [28, 'Επικρατείας'],
  [4, 'Ευβοίας'],
  [52, 'Ευρυτανίας'],
  [53, 'Ζακύνθου'],
  [33, 'Ηλείας'],
  [12, 'Ημαθίας'],
  [15, 'Ηρακλείου'],
  [36, 'Θεσπρωτίας'],
  [46, 'Ιωαννίνων'],
  [56, 'Καβάλας'],
  [26, 'Καρδίτσης'],
  [11, 'Καστοριάς'],
  [3, 'Κερκύρας'],
  [42, 'Κεφαλληνίας'],
  [32, 'Κιλκίς'],
  [39, 'Κοζάνης'],
  [38, 'Κορινθίας'],
  [27, 'Κυκλάδων'],
  [14, 'Λακωνίας'],
  [17, 'Λαρίσης'],
  [44, 'Λασιθίου'],
  [1, 'Λέσβου'],
  [45, 'Λευκάδος'],
  [41, 'Μαγνησίας'],
  [50, 'Μεσσηνίας'],
  [35, 'Ξάνθης'],
  [48, 'Πέλλης'],
  [49, 'Πιερίας'],
  [34, 'Πρεβέζης'],
  [51, 'Ρεθύμνης'],
  [16, 'Ροδόπης'],
  [58, 'Σάμου'],
  [13, 'Σερρών'],
  [40, 'Τρικάλων'],
  [22, 'Φθιώτιδας'],
  [10, 'Φλωρίνης'],
  [54, 'Φωκίδος'],
  [19, 'Χαλκιδικής'],
  [18, 'Χανίων'],
  [55, 'Χίου'],
]);
