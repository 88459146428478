import React from 'react';
import styled from 'styled-components';
import LandingNavBar from './LandingNavBar';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Button from '@mui/material/Button';
import * as colors from '../Colors';
import Avatar from '@mui/material/Avatar';
import { CardHeader } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { LinkedIn } from '@mui/icons-material';

const Front = styled.div`
  height: calc(100vh - 69px);
  font-size: 18px;
  margin: 0 auto;
  background-image: url(/landing-image_full.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 42vh;
  background-attachment: fixed;
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
`;

const MainTitle = styled.h1`
  font-size: 76px;
  color: white;
  font-family: 'Montserrat', Helvetica, san pro;
  text-shadow: 3px 3px 0 #00bcd4bf;
  text-align: center;
  @media (max-width: 890px) {
    font-size: 52px;
  }
`;

const LandingGrid = styled(Grid)({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const GridItem = styled(Item)({
  padding: '40px',
  maxWidth: '650px',
  margin: 'auto',
  textAlign: 'center',
});

const FrontTxt = styled.p`
  color: white;
  padding-top: 20px;
  line-height: 1.3;
`;

const FrontButton = styled(Button)({
  backgroundColor: `${colors.app.secondary}!important`,
  color: '#323232!important',
  fontWeight: 'bold!important',
  fontFamily: 'Source Sans Pro!important',
  marginTop: '20px!important',
});

const ImageGrid = styled(Grid)({
  height: '100%',
  padding: '10px',
  width: '100%',
});

const ImageItem = styled(Item)({
  backgroundImage: 'url(/landing-image.png)',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
});

const About = styled.div`
  background: white;
  padding: 40px 40px 40px 0;
  font-size: 18px;
`;

const AboutTitle = styled.h1`
  font-size: 76px;
  color: #323232;
  font-family: 'Montserrat', Helvetica, san pro;
  text-shadow: 3px 3px 0 #00bcd4bf;
  text-align: right;
  @media (max-width: 414px) {
    text-align: left;
    padding-left: 40px;
  }
`;

const AboutGrid = styled(Grid)({
  padding: '40px',
});

const InnerContent = styled.div`
  margin: 0 auto;
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
`;

const AboutH2 = styled.h2`
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  text-transform: uppercase;
`;

const AboutP = styled.p`
  line-height: 1.3;
  padding: 10px 0;
`;

const Highlighter = styled.span`
  color: ${(props) =>
    props.color === 'blue'
      ? colors.parties[1]
      : props.color === 'red'
      ? colors.parties[2]
      : colors.app.darkSecondary};
  font-weight: 700;
`;

const Contributor = styled(CardHeader)({
  fontFamily: 'Source Sans Pro!important',
});

const Credit = styled.p`
  font-size: 16px;
  margin: 8px 0;
  line-height: 1.3;
  span {
    color: ${colors.app.darkGrey};
    font-weight: 500;
  }
`;

const Supported = styled.div`
  width: 100%;
  font-size: 12px;
  margin-top: 15px;
  padding-left: 0px;
  margin-bottom: 40px;
`;

const Logo = styled.img`
  margin-top: 5px;
  max-width: 164px;
  width: 50%;
  display: flex;
`;

const LandingPage = () => {
  return (
    <div>
      <LandingNavBar></LandingNavBar>
      <Front id="front">
        <LandingGrid container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12}>
            <GridItem>
              <MainTitle>WEALTH OBSERVER</MainTitle>
              <FrontTxt>
                Πολιτικοί με καταθέσεις εκατομμυρίων, υπουργοί με εκατοντάδες
                ακίνητα, βουλευτές υπερχρεωμένοι με δάνεια, αλλά κατέχοντες
                χαρτοφυλάκια επενδύσεων χιλιάδων ευρώ. Όλα τα παραπάνω επιμελώς
                καλυμμένα σε μεγάλους πίνακες, δυσανάγνωστων εγγράφων σε κάποιο
                link ενός website.<br></br>
                <br></br>
                Αυτό αποφασίσαμε να αλλάξει. Οπτικοποιώντας τα δεδομένα της
                περιουσιακής κατάστασης των Ελλήνων πολιτικών, δίνουμε σε όλους
                τους πολίτες τη δυνατότητα να αποκτήσουν γνώση, να τα ερευνήσουν
                σε βάθος και να ασκήσουν ουσιαστικό έλεγχο.
              </FrontTxt>
              <FrontButton href="/incomes" size="large" variant="contained">
                Θελω να δω τα δεδομενα
              </FrontButton>
            </GridItem>
          </Grid>
          {/* <ImageGrid
            item
            md={12}
            lg={6}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            }}
          >
            <ImageItem></ImageItem>
          </ImageGrid> */}
        </LandingGrid>
      </Front>
      <About id="about">
        <InnerContent>
          <AboutTitle>ABOUT</AboutTitle>
          <AboutGrid container justifyContent="center" spacing={6}>
            <Grid item md={6} lg={4}>
              <Item>
                <AboutH2>Τα δεδομενα</AboutH2>
                <AboutP>
                  Τα δεδομένα που παρουσιάζονται στην εφαρμογή προέρχονται από
                  τις δηλώσεις περιουσιακής κατάστασης των αρχηγών κομμάτων,
                  υπουργών, βουλευτών και ευρωβουλευτών, και αντλήθηκαν από τον
                  ιστότοπο της Βουλής των Ελλήνων σε μορφή pdf. Αναγνώστηκαν με
                  αυτοματοποιημένο τρόπο και αποθηκεύθηκαν σε βάση δεδομένων.
                </AboutP>
                <AboutP>
                  Οι διαθέσιμες δηλώσεις ξεκινούν από το 2016 (χρήση 2015). Η
                  εφαρμογή ανανεώνεται κάθε φορά που δημοσιεύονται νέα Πόθεν
                  Έσχες. Λόγω των περιορισμών που θέτει ο νόμος τα στοιχεία θα
                  παραμένουν δημοσιευμένα έως και τρία χρόνια από τη λήξη της
                  θητείας του εκάστοτε πολιτικού.
                </AboutP>
                <AboutP>
                  Για να είναι εφικτή η συνολική οπτικοποίηση, τα ποσά που
                  παρουσιάζονται είναι το άθροισμα των υπόχρεων με τους/τις
                  συζύγους.
                </AboutP>
              </Item>
            </Grid>
            <Grid item md={6} lg={4}>
              <Item>
                <AboutH2>Η εφαρμογη</AboutH2>
                <AboutP>
                  Η interactive εφαρμογή στην οποία πλοηγείστε ξεκίνησε από μια
                  προσωπική ανάγκη να δούμε με έναν εύληπτο και ξεκάθαρο τρόπο
                  τα δεδομένα αυτά. Η δυσκολία που αντιμετωπίζει η πλειοψηφία
                  των δημοσιογράφων να επεξεργαστούν αποτελεσματικά τον μεγάλο
                  όγκο των δεδομένων κάθε φορά που δημοσιοποιούνται τα πόθεν
                  έσχες και η συνεπαγόμενη{' '}
                  <Highlighter>
                    επιφανειακή κάλυψη του θέματος από τα ΜΜΕ
                  </Highlighter>{' '}
                  οδηγεί μοιραία σε ένα χαμηλό ενδιαφέρον από την πλευρά των
                  πολιτών για το θέμα και συνεπώς σε{' '}
                  <Highlighter>
                    απουσία ελέγχου, κριτικής και πίεσης προς τους πολιτικούς.
                  </Highlighter>
                </AboutP>
                <AboutP>
                  Το γεγονός αυτό μας παρακίνησε να δημιουργήσουμε το παρόν
                  εργαλείο, και να «ανοίξουμε» τα δεδομένα σε όλους τους πολίτες
                  πιστεύοντας ότι με αυτόν τον τρόπο θα βοηθήσουμε μια κοινωνία
                  που φτωχοποιήθηκε δραματικά την τελευταία δεκαετία να
                  αποκτήσει πρόσβαση σε επιπλέον πληροφορίες ώστε να λαμβάνει
                  τεκμηριωμένες αποφάσεις προς το συμφέρον της στο μέλλον.
                </AboutP>
                <FrontButton href="/incomes" size="large" variant="contained">
                  Πηγαινε με στην εφαρμογη
                </FrontButton>
              </Item>
            </Grid>
            <Grid item md={6} lg={4}>
              <Item>
                <AboutH2>Credits</AboutH2>
                <Contributor
                  avatar={
                    <Avatar
                      style={{
                        border: '2px solid #323232',
                        backgroundColor: '#00BCD4',
                      }}
                      alt="Achilleas Galatsidas"
                      src="/galatsidas.png"
                      sx={{ width: 56, height: 56 }}
                    />
                  }
                  action={
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/achilleasg/"
                      style={{ color: 'inherit' }}
                    >
                      <LinkedIn />
                    </a>
                  }
                  title="Αχιλλέας Γαλατσίδας"
                  subheader="Ιδέα, data visualisation, front end development"
                />
                <Contributor
                  avatar={
                    <Avatar
                      style={{
                        border: '2px solid #323232',
                        backgroundColor: '#00BCD4',
                      }}
                      alt="Achilleas Kiritsakas"
                      src="/kiritsakas.png"
                      sx={{ width: 56, height: 56 }}
                    />
                  }
                  action={
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/achilleas-kiritsakas-9ab42956/"
                      style={{ color: 'inherit' }}
                    >
                      <LinkedIn />
                    </a>
                  }
                  title={'Αχιλλέας Κυριτσάκας'}
                  subheader="Back end development, front end development"
                />
                <Credit>
                  Φωτογραφίες πολιτικών <br></br>
                  <span>
                    <a
                      href="https://www.sooc.gr/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit' }}
                    >
                      SOOCimages
                    </a>
                    , wikipedia, προσωπικές ιστοσελίδες πολιτικών
                  </span>
                </Credit>
                <Credit>
                  Λογότυπο/επεξεργασία φωτογραφιών <br></br>
                  <span>Ευγένιος Καλοφωλιάς</span>
                </Credit>
                <Supported>
                  Supported by
                  <a
                    href="https://www.imedd.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Logo
                      width={164}
                      height={29.11}
                      alt="imedd"
                      src="/iMEdD.png"
                    ></Logo>
                  </a>
                </Supported>
                <AboutH2>Feedback</AboutH2>
                <AboutP>
                  Πιστεύουμε στη δύναμη και την αξία της συλλογικής προσπάθειας.
                  <br></br>Στείλτε μας τα σχόλια και τις παρατηρήσεις σας εδώ:{' '}
                </AboutP>
                <a
                  style={{ color: '#323232' }}
                  href="mailto:wealthobs@gmail.com"
                >
                  wealthobs@gmail.com
                </a>
              </Item>
            </Grid>
          </AboutGrid>
        </InnerContent>
      </About>
    </div>
  );
};

export default LandingPage;
