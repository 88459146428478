import * as api from '../api';
import {
  GET_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  REMOVE_POLITICIAN_ALL_NOTIFICATIONS,
} from '../constants/actionTypes';

export const fetchNotifications = (politicianID, year) => ({
  type: GET_NOTIFICATIONS,
  payload: api.getNotifications(politicianID, year),
  value: politicianID,
});

export const removeNotification = (politicianID, page, reason) => ({
  type: REMOVE_NOTIFICATION,
  politicianID,
  page,
  reason,
});

export const removePoliticianAllNotifications = (
  politicianID,
  notificationsObject
) => ({
  type: REMOVE_POLITICIAN_ALL_NOTIFICATIONS,
  politicianID,
  notificationsObject,
});
