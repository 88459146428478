import {
  GET_COMPANIES_PENDING,
  GET_COMPANIES_FULFILLED,
  GET_COMPANIES_REJECTED,
} from '../constants/actionTypes';

export const initialState = {
  politicians: [],
  parties: [],
  filters: [],
  loading: false,
  hasErrors: false,
  loaded: false,
};

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_PENDING:
      return { ...state, loading: true };
    case GET_COMPANIES_FULFILLED:
      return {
        ...state,
        politicians: action.payload.items,
        parties: action.payload.parties,
        filters: action.payload.filters,
        loading: false,
        hasErrors: false,
        loaded: true,
      };
    case GET_COMPANIES_REJECTED:
      return { ...state, loading: false, hasErrors: true, loaded: false };
    default:
      return state;
  }
}
