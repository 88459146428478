import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as colors from '../Colors';
import MenuButton from './MenuButton';
import MaterialIcon from 'material-icons-react';
import { NavLink } from 'react-router-dom';

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 21px 19px 30px;
  border-bottom: 0.5px solid ${colors.app.grey};
  @media (max-width: 1024px) {
    padding: 15px 21px 19px 20px;
  }
`;

const Logo = styled.div`
  align-self: center;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  div {
    padding-right: 2em;
    @media (max-width: 890px) {
      padding-right: 1em;
      font-size: 14px;
    }
  }
`;

const SearchButton = styled.div`
  border-left: 1px solid ${colors.app.black};
  padding-right: 0 !important;
  cursor: pointer;
  .material-icons {
    font-size: 34px;
    color: ${colors.app.secondary};
    padding: 6px 0 0 15px;
  }
`;

const StyledNavLink = styled(NavLink)`
  &.active div {
    -webkit-text-stroke: 1px ${colors.app.secondary};
    color: ${colors.app.secondary};
  }
`;

const AboutLink = styled.a`
  margin-right: 20px;
  text-decoration: none;
  color: ${colors.app.black};
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 3px;
  &:hover {
    color: ${colors.app.secondary};
  }
  @media (max-width: 890px) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

function MainMenu(props) {
  const menuItems = useSelector((state) => {
    return state.mainMenu.items;
  });

  //TODO: we can clear urlFilters that do not belong to other pages
  // const { search } = useLocation();

  return (
    <MenuContainer>
      <Logo>
        <a href="/">
          <img
            width={150}
            height={45}
            src="/logo_app_beta.png"
            alt="Wealth Observer"
          />
        </a>
      </Logo>
      <Menu>
        {menuItems.map((item) => {
          return (
            <StyledNavLink
              className="nav-link"
              to={`/${item.variable}`}
              key={`menu-item-${item.variable}`}
              // to={`${item.path}${search}`}
              // key={`menu-item-${item.path}`}
              exact
            >
              <MenuButton
                label={item.label}
                visVar={item.variable}
                notifications={item.notifications}
                // TODO: NavLink adds active className to "a" tag for the active menu item
                // so move the css from src/index.css to the right position
              />
            </StyledNavLink>
          );
        })}
        <AboutLink href="/#about">About</AboutLink>
        <SearchButton onClick={props.clickHandler}>
          <MaterialIcon icon="search" />
        </SearchButton>
      </Menu>
    </MenuContainer>
  );
}

MainMenu.propTypes = {
  menuItems: PropTypes.array,
};

export default MainMenu;
