import {
  SET_FILTERS,
  INIT_FILTERS,
  RESET_FILTERS,
  RESET_FILTERS_LOCATION_CHANGED,
} from '../constants/actionTypes';

export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const GET_POLITICIANS = 'GET POLITICIANS';
export const GET_POLITICIANS_SUCCESS = 'GET_POLITICIANS_SUCCESS';
export const GET_POLITICIANS_FAILURE = 'GET_POLITICIANS_FAILURE';

export const setSelectedItem = (item) => ({
  type: SET_SELECTED_ITEM,
  payload: item,
});

export const getPoliticians = () => ({
  type: GET_POLITICIANS,
});

export const getPoliticiansSuccess = (politicians) => ({
  type: GET_POLITICIANS_SUCCESS,
  payload: politicians,
});

export const getPoliticiansFailure = (politicians) => ({
  type: GET_POLITICIANS_FAILURE,
});

export function fetchPoliticians() {
  return async (dispatch) => {
    dispatch(getPoliticians());

    try {
      const response = await fetch('//localhost:1337/politicians?_limit=-1');
      const data = await response.json();

      dispatch(getPoliticiansSuccess(data));
    } catch (error) {
      dispatch(getPoliticiansSuccess());
    }
  };
}

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});

export const initFilters = (filters) => ({
  type: INIT_FILTERS,
  payload: filters,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
  payload: {},
});

export const resetFiltersOnLocationChange = () => ({
  type: RESET_FILTERS_LOCATION_CHANGED,
  payload: {},
});
