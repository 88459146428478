import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import store from './store';

import App from './layout/App';
import './index.css';
import './fonts.css';

Sentry.init({
  dsn: 'https://cdc747af34b94f66bdae373c6f4ac321@o1074448.ingest.sentry.io/6084677',

  maxBreadcrumbs: 50,
});

const instance = createInstance({
  urlBase: 'https://dashboard.wealthobserver.gr/',
  siteId: 1,
  trackerUrl: 'https://dashboard.wealthobserver.gr/gesetsr.php',
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

ReactDOM.render(
  <MatomoProvider value={instance}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MatomoProvider>,
  document.getElementById('root')
);
