import { selection } from 'd3-selection';

// Add a couple useful utilities to reorder elements...
selection.prototype.moveToFront = function () {
  return this.each(function () {
    this.parentNode.appendChild(this);
  });
};

selection.prototype.moveToBack = function () {
  return this.each(function () {
    const firstChild = this.parentNode.firstChild;
    if (firstChild) {
      this.parentNode.insertBefore(this, firstChild);
    }
  });
};

/**
 * appendSelect either selects a child of current selection or appends
 * one to the selection if it doesn't exist. Useful for writing idempotent
 * chart functions.
 *
 * Used like this:
 *
 * selection.appendSelect('div');
 * selection.appendSelect('div', 'with-a-class');
 * selection.appendSelect('div', 'one-class two-classes');
 * selection.appendSelect('div').appendSelect('div', 'nested');
 *
 * @param  {string} elementToAppend   String representation of element to be appended/selected.
 * @param  {String} elementClass      Class string (w/out dots) of element to be appended/
 *                                    selected. Can pass none or multiple separated by whitespace.
 * @return {object}                   d3 selection of child element
 */
selection.prototype.appendSelect = function (elementToAppend, elementClass) {
  const selector = elementClass
    ? `${elementToAppend}.${elementClass.split(' ').join('.')}`
    : elementToAppend;

  const selected = this.select(selector);

  // If element doesn't already exist, append it...
  if (selected.empty()) {
    return elementClass
      ? this.append(elementToAppend).classed(elementClass, true)
      : this.append(elementToAppend);
  }
  // ... if it does, return it.
  return selected;
};

export default selection;
