import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from '../reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';

const middleWares = [thunk, promiseMiddleware];
const composeArgs = [rootReducer];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    collapsed: function (getState, action, logEntry) {
      return false;
    },
  });

  middleWares.push(logger);

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const store = compose(applyMiddleware(...middleWares))(createStore)(
  ...composeArgs
);

export const configureStore = (initialState = {}) => {
  //TODO: use composeWithDevTools if prefer & uncomment import
  return compose(applyMiddleware(...middleWares))(createStore)(
    rootReducer,
    initialState
  );
};

export default store;
