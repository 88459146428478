import React, { useEffect, useCallback, useRef } from 'react';
import BeeswarmChart from './BeeswarmChart';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { hoveredPolitician } from '../actions/politicians';
import { setFilters } from '../actions';
import isDeepEqual from 'fast-deep-equal/react';

// Library for useEffect debuging
/* import {
  useWhatChanged,
  setUseWhatChange,
} from '@simbathesailor/use-what-changed'; */

const Chart = styled.div`
  width: 100%;
  flex: 1;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const chart = new BeeswarmChart();

function BeeswarmContainer(props) {
  const {
    dataset,
    partiesData,
    visualisedVar,
    secondVisualisedVar,
    fetched,
    mouseoutTimer,
    timerRef,
  } = props;

  const dispatch = useDispatch();
  const selectedParties = useSelector((state) => {
    return state.filters.data.parties;
  });

  const selectedPoliticians = useSelector((state) => {
    return state.filters.data.selected;
  });

  const updateHover = useCallback(
    (d, ttLeft, ttTop, xPos, yPos) => {
      dispatch(
        hoveredPolitician({
          ...d,
          ttLeft: ttLeft,
          ttTop: ttTop,
          xPos: xPos,
          yPos: yPos,
        })
      );
    },
    [dispatch]
  );

  const updateClicked = useCallback(
    (id) => {
      dispatch(setFilters({ politician: id }));
    },
    [dispatch]
  );

  const datasetRef = useRef(dataset);
  if (!isDeepEqual(datasetRef.current, dataset)) {
    datasetRef.current = dataset;
  }

  //Uncomment for useEffect debuging
  /* useWhatChanged([
    datasetRef.current,
    selectedParties,
    selectedPoliticians,
    //visualisedVar,
    secondVisualisedVar,
    //updateHover,
    //updateClicked,
    fetched,
  ]); */

  useEffect(
    () => {
      /*
      Uncomment if we want to prevent the initial renderings without the requests
      to be finished

      if (partiesData.length === 0 || dataset.length === 0) {
        return;
      }
      */
      const handleResize = () => {
        chart.data(props.dataset).draw();
      };
      window.addEventListener('resize', debounce(handleResize, 250));

      chart
        .selection('#chart')
        .data(dataset)
        .props({
          fill: '#fff',
          partiesData,
          visualisedVar,
          selectedPoliticians,
          selectedPartiesData: selectedParties,
          updateHover,
          secondVisualisedVar,
          updateClicked,
          dataFetched: fetched,
          mouseoutTimer: mouseoutTimer,
          timerRef: timerRef,
        })
        .draw();
    }, // eslint-disable-next-line
    [
      //TODO: revisit this about which are necessary dependencies
      // partiesData.length,
      datasetRef.current,
      selectedParties,
      selectedPoliticians,
      //visualisedVar,
      secondVisualisedVar,
      //updateHover,
      //updateClicked,
      fetched,
    ]
  );

  return <Chart id="chart" />;
}

export default BeeswarmContainer;
