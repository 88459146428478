import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { isUndefined } from 'lodash';
import styled from 'styled-components';
import * as colors from '../Colors';
import Dropdown from './Dropdown';
import Switch from './Switch';
import SearchItems from './SearchItems';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';
import { extent as d3extent } from 'd3-array';
import Slider from '@material-ui/core/Slider';
import { formatNumberNoSymb, getTotal } from '../utils/Helpers.js';
import { setFilters } from '../actions';

const FilterMenuContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.app.grey};
  border-radius: 22px;
  padding: 0 15px;
  margin: 0px 0 14px 0;
  position: relative;
  flex: 0 1 auto;
  align-items: center;
  @media (min-width: 890px) {
    width: calc(100vw - 145px);
  }
`;

const SliderContainer = styled.div`
  width: 20%;
  max-width: 260px;
  min-width: 180px;
  @media (max-width: 890px) {
    min-width: 150px;
  }
  display: flex;
  align-items: center;
  margin-left: 5px;
  p {
    margin-right: 10px;
    color: #4a4a4a;
    @media (max-width: 890px) {
      font-size: 14px;
    }
  }
  & .MuiSlider-root {
    color: ${colors.app.secondary};
  }
`;

const RangeSlider = styled(Slider)`
  & .MuiSlider-thumb {
    &:hover > .MuiSlider-valueLabel {
      transform: scale(1) translateY(0px);
    }
  }
  & .MuiSlider-valueLabel {
    position: relative;
    top: 0;
    left: initial;
    margin-bottom: 51px;
    & span:first-child {
      width: 100%;
      height: auto;
      padding: 2px;
      transform: initial;
      border-radius: 5px;
      text-align: center;
    }
    & span {
      transform: inherit;
    }
  }
`;

const Divider = styled.div`
  margin: 0 5px;
  border-right: 1px solid ${colors.app.grey};
  height: 100%;
`;

const Reset = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  color: ${colors.app.secondary};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  transition: opacity 0.3s;
  & p {
    @media (max-width: 890px) {
      display: none;
    }
  }
`;

function FilterMenu(props) {
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState([]);
  const {
    secondVisualisedVar,
    visualisedType,
    resetState,
    sliderAction,
    partiesData,
    itemsData,
    actionToDispatch,
    yearsData,
    reset,
    resetAction,
    switchAction,
    switchLabels,
    switchPressed,
    landTypes,
    selected,
  } = props;
  const years = useSelector((state) => {
    return state.year.all;
  });

  const politiciansData = useSelector((state) => {
    const visVar =
      props.visualisedVar === 'incomes' ? 'income' : props.visualisedVar;
    return state[visVar].politicians;
  });

  const sumTotal = secondVisualisedVar ? secondVisualisedVar : 'total';

  const minMax = useMemo(() => {
    return d3extent(politiciansData, (item) => {
      if (item.extra.records || item.extra[visualisedType]) {
        return getTotal(item, visualisedType, sumTotal);
      }
    });
  }, [politiciansData, visualisedType, sumTotal]);

  const onChangeYear = useCallback(
    (year) => {
      year === years[years.length - 1] ? resetState(false) : resetState(true);

      dispatch(setFilters({ year }));
    },
    [dispatch, resetState, years]
  );

  useEffect(
    () => {
      //when no record for politicians found
      if (isUndefined(minMax[0]) && isUndefined(minMax[1])) {
        setSliderValue([0, 0]);
      } else {
        setSliderValue(minMax);
      }
      sliderAction(minMax[0], minMax[1]);
      return () => {
        //Can't perform a React state update on an unmounted component
        sliderAction.cancel();
      };
    },
    // eslint-disable-next-line
    [minMax]
  );

  const handleSliderChange = useCallback(
    (event, newValue) => {
      setSliderValue(newValue);
      sliderAction(newValue[0], newValue[1]);
    },
    // eslint-disable-next-line
    [sliderAction] //TODO: sliderAction is a new one on every render, useCallBack on Parent Component
  );

  function valueText(value) {
    return formatNumberNoSymb(value);
  }

  switch (props.visualisedVar) {
    case 'incomes':
      return (
        <FilterMenuContainer id="filterMenu">
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Τύπος εισοδήματος"
            items={itemsData}
            visualisedType={visualisedType}
            width={250}
            action={actionToDispatch}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={1000}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    case 'savings':
      return (
        <FilterMenuContainer>
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Τράπεζες"
            items={itemsData}
            visualisedType={visualisedType}
            width={250}
            action={actionToDispatch}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={1000}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    case 'investments':
      return (
        <FilterMenuContainer>
          <SearchItems
            items={itemsData}
            action={actionToDispatch}
            reset={reset}
            label="Τίτλος Χρεογράφου"
          ></SearchItems>
          <Divider />
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={1000}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    case 'lands':
      return (
        <FilterMenuContainer>
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Τύπος ακινήτου"
            items={landTypes}
            visualisedType={visualisedType}
            width={150}
            action={actionToDispatch}
          ></Dropdown>
          {/*<Divider />
          <Dropdown
            variant="normal"
            label="Προβολή"
            items={props.showItems}
            width={150}
          ></Dropdown>*/}
          <Divider />
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <Switch
            labels={switchLabels}
            width={180}
            actionToDispatch={switchAction}
            pressed={switchPressed}
            selected={selected}
          ></Switch>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={10}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    case 'loans':
      return (
        <FilterMenuContainer>
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Πιστωτής"
            items={itemsData}
            visualisedType={visualisedType}
            width={150}
            action={actionToDispatch}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <Switch
            labels={switchLabels}
            width={180}
            actionToDispatch={switchAction}
            pressed={switchPressed}
            selected={secondVisualisedVar}
          ></Switch>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={1000}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    case 'companies':
      return (
        <FilterMenuContainer>
          <SearchItems
            items={itemsData}
            action={actionToDispatch}
            label="Επωνυμία Εταιρείας"
            reset={reset}
          ></SearchItems>
          <Divider />
          <Dropdown
            variant="chipset"
            label="Κόμματα"
            items={partiesData}
            resetState={resetState}
          ></Dropdown>
          <Divider />
          {/*<Dropdown
            variant="normal"
            label="Τύπος εταιρείας"
            items={props.companyTypes}
            width={150}
          ></Dropdown>
          <Divider />
          <Dropdown
            variant="normal"
            label="Μέγεθος"
            items={props.sizeToVisualise}
            width={150}
          ></Dropdown>
          <Divider />*/}
          <Dropdown
            variant="normal"
            label="Ετος"
            items={yearsData}
            action={onChangeYear}
          ></Dropdown>
          <Divider />
          <SliderContainer>
            <p>Εύρος</p>
            <RangeSlider
              min={minMax[0]}
              max={minMax[1]}
              value={sliderValue}
              step={1}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              valueLabelFormat={valueText}
            />
          </SliderContainer>
          <Reset visible={reset} onClick={resetAction}>
            <p>Επαναφορά</p>
            <Icon icon="refresh" />
          </Reset>
        </FilterMenuContainer>
      );
    default:
      return null;
  }
}

export default FilterMenu;
