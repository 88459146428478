import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialIcon from 'material-icons-react';
import ChipsetFilter from './ChipsetFilter';
import * as colors from '../Colors';

const Panel = styled.div`
  transition: transform 0.2s, opacity 0.2s;
  background-color: ${colors.app.darkSecondary};
  padding: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: ${(props) => (props.width ? `${props.width + 35}px` : '190px')};
  position: absolute;
  top: 50px;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  transform: ${(props) =>
    props.isOpen === true ? 'scale(1, 1)' : 'scale(0, 0)'};
  transform-origin: top left;
  margin-top: 5px;
  display: inline-block;
`;

const Title = styled.div`
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  padding: 5px;
  color: white;
  display: inline-block;
  transition: opacity 0.1s linear 0.15s;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  visibility: ${(props) => (props.isOpen === true ? 'initial' : 'hidden')};
`;

const CloseButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 5px;
  transition: opacity 0.1s linear 0.15s;
  opacity: ${(props) => (props.isOpen === true ? 1 : 0)};
  visibility: ${(props) => (props.isOpen === true ? 'initial' : 'hidden')};
`;

function DropDownChipSelect({
  isOpen,
  items,
  title,
  handleClck,
  width,
  resetState,
}) {
  return (
    <Panel width={width} isOpen={isOpen}>
      <Title isOpen={isOpen}>{title}</Title>
      <CloseButton isOpen={isOpen} onClick={handleClck} className="-jsCloseBtn">
        <MaterialIcon icon="close" color="white" />
      </CloseButton>
      <ChipsetFilter isOpen={isOpen} items={items} resetState={resetState} />
    </Panel>
  );
}

DropDownChipSelect.propTypes = {
  isOpen: PropTypes.bool,
  items: PropTypes.array,
  title: PropTypes.string,
  handleClck: PropTypes.func,
};

export default DropDownChipSelect;
