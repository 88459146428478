import { scaleOrdinal } from 'd3-scale';
import { sum as d3sum } from 'd3-array';
// modified from https://github.com/d3/d3-scale/blob/master/src/band.js
const constant = (K) => () => K;

export function variableBand() {
  var scale = scaleOrdinal().unknown(undefined),
    domain = scale.domain,
    ordinalRange = scale.range,
    r0 = 0,
    r1 = 1,
    step,
    bandwidth,
    weight = constant(1),
    weights,
    k,
    round = false,
    paddingInner = 0,
    paddingOuter = 0,
    align = 0.5;

  delete scale.unknown;

  function rescale() {
    var n = domain().length,
      reverse = r0 < r1,
      start = reverse ? r1 : r0,
      stop = reverse ? r0 : r1;

    weights = domain().map(weight);
    var s = d3sum(weights);
    step = (stop - start) / Math.max(1, n - paddingInner + paddingOuter * 2);
    bandwidth = step * (1 - paddingInner);
    start += (stop - start - step * (n - paddingInner)) * align;
    if (round) start = Math.round(start);
    var values = [],
      v = start;
    k = ((1 / s) * n * step) / (1 + paddingInner);
    for (var i = 0; i < n; i++) {
      values.push(round ? Math.round(v) : v);
      v += k * weights[i] - (step * paddingInner) / (1 + paddingInner);
    }
    return ordinalRange(reverse ? values.reverse() : values);
  }

  scale.domain = function (_) {
    return arguments.length ? (domain(_), rescale()) : domain();
  };

  scale.range = function (_) {
    return arguments.length
      ? (([r0, r1] = _), (r0 = +r0), (r1 = +r1), rescale())
      : [r0, r1];
  };

  scale.rangeRound = function (_) {
    return ([r0, r1] = _), (r0 = +r0), (r1 = +r1), (round = true), rescale();
  };

  scale.weights = function (_) {
    if (_ === undefined) return weights;
    weight = _;
    return rescale();
  };

  scale.bandwidth = function (d) {
    return d === undefined ? bandwidth : weight(d) * k;
  };

  scale.step = function () {
    return step;
  };

  scale.round = function (_) {
    return arguments.length ? ((round = !!_), rescale()) : round;
  };

  scale.padding = function (_) {
    return arguments.length
      ? ((paddingInner = Math.min(1, (paddingOuter = +_))), rescale())
      : paddingInner;
  };

  scale.paddingInner = function (_) {
    return arguments.length
      ? ((paddingInner = Math.min(1, _)), rescale())
      : paddingInner;
  };

  scale.paddingOuter = function (_) {
    return arguments.length ? ((paddingOuter = +_), rescale()) : paddingOuter;
  };

  scale.align = function (_) {
    return arguments.length
      ? ((align = Math.max(0, Math.min(1, _))), rescale())
      : align;
  };

  scale.copy = function () {
    return variableBand(domain(), [r0, r1])
      .weights(weight)
      .round(round)
      .paddingInner(paddingInner)
      .paddingOuter(paddingOuter)
      .align(align);
  };

  return initRange.apply(rescale(), arguments);
}

function initRange(domain, range) {
  switch (arguments.length) {
    case 0:
      break;
    case 1:
      this.range(domain);
      break;
    default:
      this.range(range).domain(domain);
      break;
  }
  return this;
}
